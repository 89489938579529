import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid, TextField } from '@hbf/dsl/legacy';

import { DateRangePickerInputsWrapper } from '../../DateRangePicker/DateRangePickerInputsWrapper';
import { FOCUSED_FIELD, FormatDate, TranslateFunc } from '../../types';

const useStyles = makeStyles()(theme => ({
  dateInput: {
    textAlign: 'center',
  },
  focusedDateInput: {
    color: theme.palette.primary.main,
  },
  underline: {
    '&:before': {
      borderBottom: 'none!important',
    },
  },
}));

interface Props {
  focused: FOCUSED_FIELD | null;
  from?: Date;
  onFieldFocusChange: (field: FOCUSED_FIELD) => void;
  to?: Date;
  formatDate: FormatDate;
  T: TranslateFunc;
}

const DialogTitle: React.FC<Props> = ({
  from,
  to,
  focused,
  onFieldFocusChange,
  formatDate,
  T,
}) => {
  const { classes } = useStyles();

  return (
    <DateRangePickerInputsWrapper
      focused={focused}
      fromInputProps={{}}
      from={from}
      fromEmptyValueLabel={T('Move-in date')}
      onFieldFocusChange={onFieldFocusChange}
      to={to}
      toEmptyValueLabel={T('Move-out date')}
      toInputProps={{}}
      formatDate={formatDate}
    >
      {({ from, isFromFocused, isToFocused, onFromFocus, onToFocus, to }) => (
        <Grid container>
          <Grid item xs={5}>
            <TextField
              data-test-locator="DatePickerDialog / Dialog Title / From"
              focused={isFromFocused}
              inputProps={{
                readOnly: true,
                className: `${classes.dateInput} ${
                  isFromFocused ? classes.focusedDateInput : ''
                }`,
              }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
              onClick={onFromFocus}
              size="medium"
              value={from}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <TextField
              data-test-locator="DatePickerDialog / Dialog Title / To"
              focused={isToFocused}
              inputProps={{
                readOnly: true,
                className: `${classes.dateInput} ${
                  isFromFocused ? classes.focusedDateInput : ''
                }`,
              }}
              InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
              onClick={onToFocus}
              size="medium"
              value={to}
            />
          </Grid>
        </Grid>
      )}
    </DateRangePickerInputsWrapper>
  );
};

export { DialogTitle };
