import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reportError } from 'ha/helpers/bugReporter/reportError';

import { AppServices } from 'ha/services/getAppServices';
import { Locations } from './Locations';
import { locationsMapped } from './constants';
// for more info please visit: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
const PLACES_STATUS_TO_REPORT = [
  'INVALID_REQUEST',
  'OVER_QUERY_LIMIT',
  'UNKNOWN_ERROR',
  'REQUEST_DENIED',
];
// This is our workaround to manage in a better way the injection of the services from a thunk
// The main idea here is to abstract the container of the creation of the autocompleteService
// and only manage getPlacePredictions because in the future this can be changed/replaced with another service
// main issue about services in thunks: https://github.com/housinganywhere/ha/pull/5221
export const loadSuggestionsService =
  ({ value, predictionTypes }: { value: string; predictionTypes: string[] }) =>
  (_: Dispatch, getState: () => any, { googleMapApi }: AppServices) => {
    return googleMapApi.init().then(
      ({ autocompleteService }) =>
        new Promise((resolve, reject) => {
          autocompleteService.getPlacePredictions(
            {
              input: value,
              types: predictionTypes,
            },
            (results, status) => {
              if (status === 'OK') {
                // we use google places api for autocomplete
                // and immobiliare geocoder to find a city based on a string google places provided
                // sometimes they don't match or cannot be found so they're going to be mapped into different query
                resolve(
                  results?.map(res => ({
                    ...res,
                    description:
                      locationsMapped[res.description] ?? res.description,
                  })),
                );
              }

              if (status === 'ZERO_RESULTS') {
                resolve([]);
              }

              if (PLACES_STATUS_TO_REPORT.includes(status)) {
                reportError(`location input error ${status}`);
                reject(new Error(status));
              }

              reject(new Error(status));
            },
          );
        }),
    );
  };

const LocationsEnhanced = connect(
  null,
  (dispatch, { predictionTypes }: { predictionTypes: string[] }) => ({
    loadSuggestions: (value: string) =>
      // @ts-ignore
      dispatch(loadSuggestionsService({ value, predictionTypes })),
  }),
  // @ts-ignore
)(Locations);
export { LocationsEnhanced };
