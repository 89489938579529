import isNil from 'lodash-es/isNil';
import {
  DEFAULT_SEARCH_FLEXIBILITY,
  FlexibilityAnalyticsLabel,
} from 'ha/constants/FlexibleSearchDates';

type FlexDaysAnalyticsLabelVal =
  (typeof FlexibilityAnalyticsLabel)[keyof typeof FlexibilityAnalyticsLabel];

export const getFlexDaysAnalyticsLabel = (
  flexDays?: number | null,
): FlexDaysAnalyticsLabelVal =>
  FlexibilityAnalyticsLabel[
    isNil(flexDays) ? DEFAULT_SEARCH_FLEXIBILITY.Search : flexDays
  ];
