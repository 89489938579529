import React from 'react';
import { DayPicker, DayPickerProps, DateRange } from 'react-day-picker';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { CaptionSubtitle } from '../types';
import { getDateFnLocale } from '../helpers/getDateFnLocale';

import { Caption } from './Caption';
import { WeekDays } from './WeekDays';
import { Day } from './Day';
import { DAY_COLUMN_SIZE_DESKTOP, DAY_COLUMN_SIZE_MOBILE } from '../constants';
import { CalendarContextProvider } from './CalendarContext';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '--rdp-cell-size': units.rem(2),
    'rdp-multi_months': {
      position: 'relative',
    },
  },
  day: {
    '&.dayBlocked': {
      color: theme.palette.grey[400],
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[300],
      background: `linear-gradient(-45deg, transparent 0%, transparent 48%, ${theme.palette.grey[400]} 49%, ${theme.palette.grey[400]} 50%, transparent 51%, transparent 100%)`,
    },
  },
  cell: {
    flex: 1,
    fontSize: units.rem(1),
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    transition: 'background-color 0.5s ease',
    border: 'none',
  },
  day_hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  day_disabled: {
    pointerEvents: 'none',
    color: theme.palette.grey[300],
  },
  day_range_end: {
    color: 'white !important',
    borderBottomRightRadius: '50% !important',
    borderTopRightRadius: '50% !important',
    borderBottomLeftRadius: '0 !important',
    borderTopLeftRadius: '0 !important',
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  day_range_start: {
    color: 'white !important',
    borderBottomLeftRadius: '50% !important',
    borderTopLeftRadius: '50% !important',
    borderBottomRightRadius: '0 !important',
    borderTopRightRadius: '0 !important',
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  day_range_middle: {
    backgroundColor: `#E2E9EE !important`,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '0 !important',
    color: 'inherit !important',
  },
  day_selected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  weekdays: {},
  months: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      placeItems: 'flex-start',
      position: 'relative',
    },
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  vhidden: {
    display: 'none',
  },
  month: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '300px',
    },
  },
  caption: {},
  head_row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2px 0',
  },
  dayLabel: {
    width: DAY_COLUMN_SIZE_DESKTOP,
    textAlign: 'center',
    color: theme.palette.text.hint,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },

    [theme.breakpoints.down('md')]: {
      width: DAY_COLUMN_SIZE_MOBILE,
    },
  },
  weekLabel: {
    width: DAY_COLUMN_SIZE_DESKTOP,
    textAlign: 'center',
    color: theme.palette.grey[700],

    [theme.breakpoints.down('md')]: {
      width: DAY_COLUMN_SIZE_MOBILE,
    },
  },
  day_outside: {
    backgroundColor: 'inherit!important',
    height: DAY_COLUMN_SIZE_DESKTOP + 4,
    width: DAY_COLUMN_SIZE_DESKTOP + 4,

    [theme.breakpoints.down('md')]: {
      height: DAY_COLUMN_SIZE_MOBILE + 4,
      width: DAY_COLUMN_SIZE_MOBILE + 4,
    },
  },
  iconButton: {
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
    outline: 'none',
  },
  interactionDisabled: {
    display: 'inline-block',
  },
  weekday: {},
  weekNumber: {},
  day_today: {},
  tbody: {
    minHeight: '204px',
    display: 'block',
  },
  head_cell: {
    // ? This is a hack to reset some of the default styles from legacy css
    border: 'inherit',
    padding: 'inherit',
  },
  table: {
    margin: '0 auto',
    width: 'auto',
  },
  disabled: {},
  container: {
    maxWidth: '280px',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'none',
    },
  },
  navBar: {},
  navButtonPrev: {},
  navButtonNext: {},
  footer: {},
  todayButton: {},
  navButtonInteractionDisabled: {},
}));

interface OwnProps {
  captionSubtitle?: CaptionSubtitle;
  classes?: { [key: string]: string };
  highlightMoveInWindows?: boolean;
  moveInWindows?: DateRange[];
  moveInWindowsTooltip: string;
  customTooltip?: React.ElementType<{ day: Date }>;
  localeString?: string;
  defaultMonth?: Date;
}

export type Props = DayPickerProps & OwnProps;

const Calendar: React.FC<Props> = props => {
  const {
    captionSubtitle,
    classes: customClasses = {},
    highlightMoveInWindows = false,
    moveInWindows,
    moveInWindowsTooltip,
    month,
    customTooltip,
    localeString = 'en-GB',
    defaultMonth = null,
  } = props;
  const { classes } = useStyles();

  const locale = React.useMemo(
    () => props.locale ?? getDateFnLocale(localeString.toLowerCase()),
    [localeString, props.locale],
  );

  const calendarContextValue = React.useMemo(
    () => ({
      highlightMoveInWindows,
      moveInWindows,
      moveInWindowsTooltip,
      customTooltip,
      captionSubtitle,
    }),
    [
      highlightMoveInWindows,
      moveInWindows,
      moveInWindowsTooltip,
      customTooltip,
      captionSubtitle,
    ],
  );

  return (
    <CalendarContextProvider value={calendarContextValue}>
      <DayPicker
        components={{
          Caption: Caption as () => React.ReactElement,
          Day: Day as () => React.ReactElement,
          HeadRow: WeekDays as unknown as () => React.ReactElement,
        }}
        {...props}
        classNames={{
          ...classes,
          ...customClasses,
        }}
        defaultMonth={defaultMonth ?? month}
        locale={locale}
      />
    </CalendarContextProvider>
  );
};

export { Calendar };
