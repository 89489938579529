import React from 'react';

import { getToday, convertDateToUTC } from '@ha/date';
import { Grid } from '@hbf/dsl/legacy';

import { calcFirstAvailableToDate } from '../helpers/calcFirstAvailableToDate';
import { firstAvailableDay } from '../helpers/firstAvailableDay';
import { isDayAfter } from '../helpers/isDayAfter';
import { isDayBefore } from '../helpers/isDayBefore';
import { isDayInMoveInWindow } from '../helpers/isDayInMoveInWindow';
import { isFromDateDisabled } from '../helpers/isFromDateDisabled';
import { isToDateDisabled } from '../helpers/isToDateDisabled';
import { setDateTimeToNoon } from '../helpers/setDateTimeToNoon';
import { DatePickerDialog, DatePickerPopover } from '../Overlays';
import {
  DateRangePickerProps,
  DateSelectionRules,
  FOCUSED_FIELD,
  DayPickerProps,
  DATEPICKER_VARIANT,
} from '../types';

import { DateRangePickerInputs } from './DateRangePickerInputs';
import { DateRangePickerOverlay } from './DateRangePickerOverlay';

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  AdditionalInfo = null,
  autoClose = true,
  buttonProps = {},
  CaptionSubtitle,
  clearDatesButton,
  DatePickerInputs = DateRangePickerInputs,
  datePickerProps = {},
  exclusions = [],
  formatDate,
  fromEmptyValueLabel,
  fromInputLabel,
  fromInputProps = {},
  hasFlexibleDatesOption = false,
  hasMoveInMoveOutTitle = false,
  hasMoveInMoveOutSubtitle = false,
  header = null,
  initialValidation = false,
  listingFlexibleDays,
  localeString,
  maxDate = null,
  minDate = getToday(),
  minimumPeriod = 0,
  moveInWindows = [],
  moveInWindowsTooltip,
  noEndDate = false,
  noEndDateLabel,
  onBlur,
  onDateChange,
  overlayDoneBtnLabel,
  required = false,
  showSingleMonth = false,
  T,
  toEmptyValueLabel,
  toInputLabel,
  toInputProps = {},
  value: { from, to, flexDays },
  variant = DATEPICKER_VARIANT.INLINE,
  withoutDividers = false,
  dialogTitle,
  onOverlayDone,
  onOverlayCancel,
  onOverlayExit,
  fullScreenDialog,
  showCancelButton,
  maximumStay = 0,
  advertiserName,
  listingType,
}) => {
  const [enteredTo, setEnteredTo] = React.useState<Date | undefined>(to);
  const [focused, setFocused] = React.useState<FOCUSED_FIELD | null>(null);
  const [fromDatePickerMonth, setFromDatePickerMonth] = React.useState<Date>();
  const [toDatePickerMonth, setToDatePickerMonth] = React.useState<Date>();

  const today = getToday();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dateSelectionRules: DateSelectionRules = {
    minDate,
    maxDate,
  };

  React.useEffect(() => {
    if (from && initialValidation) {
      let isFromDateInvalid = isFromDateDisabled(
        from,
        exclusions,
        minimumPeriod,
        dateSelectionRules,
      );

      if (!isFromDateInvalid && moveInWindows.length > 0) {
        isFromDateInvalid = !isDayInMoveInWindow(moveInWindows, from);
      }
      let isToDateInvalid = false;

      if (to) {
        isToDateInvalid = isToDateDisabled(
          listingFlexibleDays,
          to,
          from,
          exclusions,
          minimumPeriod,
          maximumStay,
        );
      }

      if (isFromDateInvalid || isToDateInvalid) {
        onDateChange({
          from: undefined,
          to: undefined,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // reset selected range if to becomes undefined (initial validation)
    if (!to && enteredTo) {
      setEnteredTo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  // whether a "From" date is disabled
  const isFromDisabled = (day: Date) =>
    isFromDateDisabled(day, exclusions, minimumPeriod, dateSelectionRules);
  // whether a "To" date is disabled
  const isToDisabled = (day: Date) =>
    isToDateDisabled(
      listingFlexibleDays,
      day,
      from,
      exclusions,
      minimumPeriod,
      maximumStay,
      dateSelectionRules,
    );

  // From/To change handlers
  const onFromDateChange = React.useCallback(
    (date: Date) => {
      setFocused(FOCUSED_FIELD.TO);

      if (to) {
        if (isDayAfter(date, to)) {
          onDateChange({
            from: setDateTimeToNoon(date),
            to: undefined,
            flexDays,
          });

          setEnteredTo(undefined);

          return;
        }

        if (minimumPeriod) {
          const isDateDisabled = isToDateDisabled(
            listingFlexibleDays,
            to,
            date,
            exclusions,
            minimumPeriod,
            maximumStay,
          );

          if (isDateDisabled) {
            onDateChange({
              from: setDateTimeToNoon(date),
              to: undefined,
              flexDays,
            });

            setEnteredTo(undefined);

            return;
          }
        }
      }

      onDateChange({
        from: setDateTimeToNoon(date),
        to,
        flexDays,
      });
    },
    [
      to,
      onDateChange,
      flexDays,
      minimumPeriod,
      listingFlexibleDays,
      exclusions,
      maximumStay,
    ],
  );

  const onToDateChange = React.useCallback(
    (date: Date) => {
      if (from && isDayBefore(date, from)) {
        onDateChange({
          from: setDateTimeToNoon(date),
          to: undefined,
          flexDays,
        });

        setEnteredTo(undefined);
        setFocused(FOCUSED_FIELD.TO);

        return;
      }

      onDateChange({
        from,
        to: setDateTimeToNoon(date),
        flexDays,
      });
      setEnteredTo(setDateTimeToNoon(date));

      setFocused(null);
    },
    [flexDays, from, onDateChange],
  );

  const onFlexibilityDaysChange = React.useCallback(
    (flexDays: number) => {
      onDateChange({
        from,
        to,
        flexDays,
      });
    },
    [from, onDateChange, to],
  );

  const onMouseEnter = React.useCallback(
    (day: Date) => {
      if (!from) {
        return;
      }
      const isRangeSelected = from && to;

      if (isRangeSelected) {
        return;
      }
      if (isDayBefore(day, from)) {
        setEnteredTo(undefined);

        return;
      }
      if (!to) {
        setEnteredTo(day);
      }
    },
    [from, to],
  );

  const onDatesClear = React.useCallback(() => {
    onDateChange({
      from: undefined,
      to: undefined,
      flexDays,
    });

    setEnteredTo(undefined);
    setFocused(FOCUSED_FIELD.FROM);
  }, [flexDays, onDateChange]);

  const onOverlayExitInternal = React.useCallback(() => {
    setEnteredTo(to);
    setFocused(null);
    if (onBlur) {
      onBlur();
    }
  }, [to, onBlur]);

  const onOverlayOpen = React.useCallback((field: FOCUSED_FIELD) => {
    setFocused(field);
  }, []);

  const onNoEndDate = React.useCallback(() => {
    onDateChange({
      from,
      to: undefined,
      flexDays,
    });
    setFocused(null);
    setEnteredTo(undefined);
  }, [flexDays, from, onDateChange]);

  // DatePickers props
  const firstAvailableDate = React.useMemo(() => {
    const localDate = new Date(
      firstAvailableDay(exclusions, listingFlexibleDays, minimumPeriod) ||
        today,
    );

    // to make sure unavailable date will not be selectable on the timezones behind the listing local time.
    return convertDateToUTC(localDate);
  }, [exclusions, listingFlexibleDays, minimumPeriod, today]);

  const firstAvailableToDate = () => {
    if (to) {
      return to;
    }

    if (from && minimumPeriod) {
      return calcFirstAvailableToDate(from, minimumPeriod, listingFlexibleDays);
    }

    return from;
  };

  const commonDatePickerProps = {
    numberOfMonths: !showSingleMonth ? 2 : 1,
    ...datePickerProps,
    mode: 'range',
    selected: from ? { from, to: enteredTo } : undefined,
  };

  const fromDatePickerProps = {
    ...commonDatePickerProps,
    onDayClick: onFromDateChange,
    onMonthChange: (changedMonth: Date) => {
      setFromDatePickerMonth(changedMonth);
    },
    month: fromDatePickerMonth || from || firstAvailableDate,
    modifiers: {
      disabled: isFromDisabled,
      start: from || false,
      end: enteredTo || false,
    },
  };

  const toDatePickerProps = {
    ...commonDatePickerProps,
    onDayClick: onToDateChange,
    onMonthChange: (changedMonth: Date) => {
      setToDatePickerMonth(changedMonth);
    },
    month: toDatePickerMonth || firstAvailableToDate(),
    modifiers: {
      start: from || false,
      end: enteredTo || false,
      disabled: isToDisabled,
    },
    onDayMouseEnter: onMouseEnter,
  };

  const finalDatePickerProps =
    focused === FOCUSED_FIELD.FROM ? fromDatePickerProps : toDatePickerProps;

  // DatePicker Inputs props
  const fromDateInputProps = {
    fullWidth: true,
    'data-test-locator': 'DateRangePicker / From',
    ...fromInputProps,
    label: fromInputLabel ?? T('Select'),
    required,
  };
  const toDateInputProps = {
    fullWidth: true,
    'data-test-locator': 'DateRangePicker / To',
    ...toInputProps,
    label: toInputLabel ?? T('Select'),
    required,
  };

  const Wrappers = {
    [DATEPICKER_VARIANT.INLINE]: DatePickerPopover,
    [DATEPICKER_VARIANT.DIALOG]: DatePickerDialog,
    [DATEPICKER_VARIANT.RESPONSIVE]: !showSingleMonth
      ? DatePickerPopover
      : DatePickerDialog,
  };

  return (
    <Grid container justifyContent="center">
      <DateRangePickerOverlay
        advertiserName={advertiserName}
        AdditionalInfo={AdditionalInfo}
        autoClose={autoClose}
        buttonProps={buttonProps}
        CaptionSubtitle={CaptionSubtitle}
        clearDatesButton={clearDatesButton}
        DatePickerInputs={DatePickerInputs}
        datePickerProps={finalDatePickerProps as DayPickerProps}
        dialogTitle={dialogTitle}
        flexDays={flexDays}
        focused={focused}
        formatDate={formatDate}
        from={from}
        fromEmptyValueLabel={fromEmptyValueLabel ?? T('Move-in date')}
        fromInputProps={fromDateInputProps}
        fullScreenDialog={fullScreenDialog}
        hasFlexibleDatesOption={hasFlexibleDatesOption}
        hasMoveInMoveOutTitle={hasMoveInMoveOutTitle}
        hasMoveInMoveOutSubTitle={hasMoveInMoveOutSubtitle}
        isDesktop={!showSingleMonth}
        header={header}
        localeString={localeString}
        minimumPeriod={minimumPeriod}
        maximumPeriod={maximumStay}
        moveInWindows={moveInWindows}
        moveInWindowsTooltip={
          moveInWindowsTooltip ??
          T('date_picker.move_in_window.not_available_tip')
        }
        noEndDate={noEndDate}
        noEndDateLabel={noEndDateLabel ?? T('No end date')}
        onDatesClear={onDatesClear}
        onFlexDaysChange={onFlexibilityDaysChange}
        onNoEndDate={onNoEndDate}
        onOverlayExit={() => {
          onOverlayExitInternal();
          onOverlayExit?.();
        }}
        onOverlayOpen={onOverlayOpen}
        onOverlayDone={onOverlayDone}
        onOverlayCancel={onOverlayCancel}
        overlayDoneBtnLabel={overlayDoneBtnLabel ?? T('Done')}
        showCancelButton={showCancelButton}
        T={T}
        to={to}
        toEmptyValueLabel={toEmptyValueLabel ?? T('Move-out date')}
        toInputProps={toDateInputProps}
        withoutDividers={withoutDividers}
        Overlay={Wrappers[variant]}
        listingType={listingType}
      />
    </Grid>
  );
};

export { DateRangePicker };
export default DateRangePicker;
