import { addMonths, diffDay } from '@ha/date';

const calculateMinimumSelectedDays = (
  startDate: string,
  minimalPeriod: number,
  listingFlexibleDays: number,
): number => {
  if (!minimalPeriod) {
    return 0;
  }

  return (
    diffDay(addMonths(startDate, minimalPeriod), startDate) -
    listingFlexibleDays
  );
};

export { calculateMinimumSelectedDays };
