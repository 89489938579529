import React from 'react';
import dayjs from 'dayjs';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Grid, TabPanel, Tabs, Tab, units } from '@hbf/dsl/legacy';
import { Calendar } from '@ha/marketplace/feature-date-picker';

import { useIntl } from 'ha/i18n';
import { getFromStorage, setInStorage } from 'ha/utils/storage';

import { MonthAvailability } from './MonthAvailability';
import { DateSelectionOptions, SELECTED_DATE_TYPE_KEY } from './types';

const { useState } = React;

const useStyles = makeStyles()((theme: Theme) => ({
  body: {
    backgroundColor: theme.palette.common.white,
    borderBottomLeftRadius: units.rem(0.25),
    borderBottomRightRadius: units.rem(0.25),
  },
  tabsWrapper: {
    marginBottom: theme.spacing(3),
  },
  tabsRoot: {
    border: units.border(1, 'solid', theme.palette.grey[300]),
    borderRadius: units.rem(0.25),
    minHeight: units.rem(2.5),
  },
  tabsIndicator: {
    width: '50% !important',
  },
  tabSelected: {
    background: theme.palette.grey[50],
    fontWeight: theme.typography.fontWeightBold,
  },
  tabSelectedSecond: {
    left: '50% !important',
  },
  tabRoot: {
    textTransform: 'capitalize',
    color: theme.palette.grey[700],
    minHeight: units.rem(2.5),
  },
  monthAvailabilityWrapper: {
    overflowY: 'auto',
    maxHeight: units.rem(18),
  },
  selected: {
    color: theme.palette.common.white,
    borderBottomLeftRadius: '50%',
    borderTopLeftRadius: '50%',
    '& > button': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover > button': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

interface Props {
  setSelectedDate: (date: Date, type: DateSelectionOptions) => void;
  selectedDate: Date;
}

enum TabTypes {
  date = 0,
  month = 1,
}

export const MoveInSelectorContent: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const { T, locale } = useIntl();
  const { classes, cx } = useStyles();

  /*
    After selection a date the component will be destroyed therefore we can't remember what was the selection type thus it will be persisted in localstorage
    so we can recover it on re-opening the datepicker
  */
  const [type, setType] = useState<DateSelectionOptions>(
    (getFromStorage(SELECTED_DATE_TYPE_KEY) as DateSelectionOptions) || 'date',
  );
  const [activeTab, setActiveTab] = React.useState(
    selectedDate.getDate() === dayjs(selectedDate).daysInMonth() &&
      type === 'month'
      ? 1
      : 0,
  );

  const onChange = (date: Date, _type: DateSelectionOptions) => {
    setSelectedDate(date, _type);
    setInStorage(SELECTED_DATE_TYPE_KEY, _type);
    setType(_type);
  };

  const disablePrevDaysModifier = React.useMemo(
    () => ({
      before: new Date(),
    }),
    [],
  );

  return (
    <Grid container direction="column">
      <Grid
        item
        className={
          activeTab === TabTypes.date ? undefined : classes.tabsWrapper
        }
      >
        <Tabs
          value={activeTab}
          onChange={(_: React.SyntheticEvent, value: TabTypes) =>
            setActiveTab(value)
          }
          variant="fullWidth"
          textColor="secondary"
          classes={{
            root: classes.tabsRoot,
            indicator: cx(classes.tabsIndicator, {
              [classes.tabSelectedSecond]: activeTab === 1,
            }),
          }}
        >
          <Tab
            label={T('date')}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
          <Tab
            label={T('month')}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        </Tabs>
      </Grid>

      <Grid item>
        <TabPanel value={activeTab} index={0}>
          <Calendar
            localeString={locale}
            data-test-locator="Move in calendar"
            moveInWindowsTooltip=""
            modifiers={{
              selected: selectedDate,
              disabled: disablePrevDaysModifier,
            }}
            classes={classes}
            numberOfMonths={1}
            onDayClick={(date: Date) => onChange(date, 'date')}
            selected={type === 'date' ? selectedDate : undefined}
            defaultMonth={selectedDate}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <div className={classes.monthAvailabilityWrapper}>
            <MonthAvailability
              value={type === 'month' ? selectedDate : undefined}
              onMonthClick={(month: Date) => onChange(month, 'month')}
            />
          </div>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
