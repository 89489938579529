import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid, Popover, units } from '@hbf/dsl/legacy';

import { OverlayProps } from '../types';
import { PopoverActions } from './Popover/PopoverActions';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: units.rem(2),
    paddingLeft: units.rem(1.75),
  },
  flexDays: {
    paddingLeft: units.rem(0.75),
    width: '100%',
    marginLeft: units.rem(1),
  },
  popoverRoot: {
    padding: units.yx(0, 1),
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
    maxWidth: '624px',
  },
}));

const DatePickerPopover: React.FC<
  Omit<OverlayProps, 'onFieldFocusChange'>
> = props => {
  const { classes } = useStyles();
  const {
    containerRef,
    children,
    FlexibleDatesOption,
    MoveInMoveOutTitle,
    MoveInMoveOutSubtitle,
    onOverlayClose,
    onOverlayExit,
    open,
    OverlayActions,
    OverlayInfo,
    OverlayTitle,
  } = props;

  return (
    <Popover
      anchorEl={containerRef.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      data-test-locator="DatePicker / Overlay / Popover"
      marginThreshold={4}
      onClose={onOverlayClose}
      open={open}
      PaperProps={{
        classes: { root: classes.popoverRoot },
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      transitionDuration={0}
      TransitionProps={{
        onExit: onOverlayExit,
      }}
    >
      {OverlayTitle}

      <Grid container justifyContent="center" className={classes.container}>
        {MoveInMoveOutTitle}
        {MoveInMoveOutSubtitle}
        <div className={classes.flexDays}>{FlexibleDatesOption}</div>
      </Grid>

      {children}
      {OverlayInfo}
      <PopoverActions>{OverlayActions}</PopoverActions>
    </Popover>
  );
};

export { DatePickerPopover };
