import React from 'react';

import { MenuItem, ListItemText, Paper } from '@hbf/dsl/legacy';
import {
  SuggestionArgs,
  RenderInputFn,
  RenderMenuArgs,
  AutocompleteTextInput,
} from './Autocomplete';

export interface SimpleItem {
  id: string;
  label: string;
  disabled?: boolean;
  placeId?: string;
}

// investigation needed for improving this functions using theme
// https://housinganywhere.atlassian.net/browse/HA-2573
function renderSuggestion({
  item,
  highlighted,
  value,
  props,
}: SuggestionArgs<SimpleItem>): React.ReactNode {
  const isSelected = value === item.label;

  return (
    <MenuItem
      key={item.id}
      selected={highlighted}
      disabled={item.disabled}
      style={{ fontWeight: isSelected ? 500 : 400 }}
      {...props}
    >
      <ListItemText>{item.label}</ListItemText>
    </MenuItem>
  );
}

function renderMenu({ key, children }: RenderMenuArgs): React.ReactNode {
  return (
    <Paper data-test-locator="Location - auto-suggest" key={key} square>
      {children}
    </Paper>
  );
}

function getItemValue(item: SimpleItem) {
  return item.label;
}

interface RequiredProps {
  onChange: (value: string) => void;
  onFocus?: () => void;
  onSelect: (value: string, item: SimpleItem) => void;
  renderInput: RenderInputFn;
  items: SimpleItem[];
  clearIcon?: React.ReactNode;
}

interface DefaultProps {
  value?: string;
  id?: string;
}

type Props = RequiredProps & DefaultProps;

// This component is accepting a renderInput fn
// The main purpose of this component is to define a ui about
// how to render MENU for the particular managing of locations
// We accept a facc for the input because it usually needs to be controlled
// by something else, i.e: redux form field, formik, etc., which usually they
// need to pass specific props for errors, warnings, etc.
export const LocationAutocompleteInput: React.FC<Props> = ({
  id,
  onChange,
  onSelect,
  onFocus,
  items,
  renderInput,
  value = '',
  clearIcon,
}) => (
  <AutocompleteTextInput
    value={value}
    id={id}
    items={items}
    onChange={onChange}
    onFocus={onFocus}
    onSelect={onSelect}
    renderInput={renderInput}
    renderSuggestion={renderSuggestion}
    renderMenu={renderMenu}
    getItemValue={getItemValue}
    selectOnBlur={false}
    clearIcon={clearIcon}
  />
);
