import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {
  getToday,
  isDayBetween,
  addDays,
  isBefore,
  diffDay,
  NO_END,
  NO_START,
} from '@ha/date';
import { DateRange } from '../types';

import { calculateMinimumSelectedDays } from './calculateMinimumSelectedDays';

interface ExclusionDate {
  from: string;
  to: string;
}

type ExclusionsType = ExclusionDate | DateRange;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const isInCorrect = (date: Date | string): boolean =>
  dayjs(date).isSameOrAfter(NO_END) || dayjs(date).isSameOrBefore(NO_START);

const firstAvailableDay = (
  ranges: ExclusionsType[] = [],
  listingFlexibleDays: number,
  minimalPeriod = 0,
  minimalDate: string = getToday(),
): string | null => {
  if (!ranges.length) {
    return minimalDate;
  }

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  const sorted = ranges.sort(
    (range1: ExclusionsType, range2: ExclusionsType) =>
      // @ts-ignore
      Number(dayjs(range1.from)) - Number(dayjs(range2.from)),
  );
  let guess: string = minimalDate;

  sorted.some(({ from, to }) => {
    // @ts-ignore
    if (isBefore(guess, from)) {
      const minimumSelectedDays = calculateMinimumSelectedDays(
        guess,
        minimalPeriod,
        listingFlexibleDays,
      );
      // @ts-ignore
      const diffInDays = diffDay(from, guess);
      const doesDayFit = diffInDays >= 0 && diffInDays > minimumSelectedDays;

      if (doesDayFit) {
        return true;
      }
      // @ts-ignore
      guess = addDays(dayjs(to).toISOString(), 1);
    }
    // @ts-ignore
    if (isDayBetween(from, to, guess)) {
      // @ts-ignore
      guess = addDays(dayjs(to).toISOString(), 1);
    }
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    return false;
  });

  return isInCorrect(guess) ? null : guess;
};
export { firstAvailableDay };
