import React from 'react';

import { Box, Grid } from '@hbf/dsl/legacy';

import { isDayAfter } from '../helpers/isDayAfter';
import { isDayBefore } from '../helpers/isDayBefore';
import { setDateTimeToNoon } from '../helpers/setDateTimeToNoon';

import { Calendar } from '../Calendar/Calendar';
import { CalendarFooter } from '../Calendar/CalendarFooter';
import { CalendarInfo } from '../Calendar/CalendarInfo';
import { DateInput } from '../DateInput/DateInput';

import { SelectDatePickerInput } from './SelectDatePickerInput';
import { DatePickerPopover } from '../Overlays';
import { SelectDatePickerProps } from '../types';

const SelectDatePicker: React.FC<SelectDatePickerProps> = ({
  AdditionalInfo,
  CaptionSubtitle,
  datePickerProps,
  inputLabel,
  inputProps,
  justifyContent = 'center',
  maxDate = null,
  minDate = new Date(),
  onBlur,
  onDateChange,
  onOverlayClose,
  onOverlayOpen,
  pickerCaption,
  value,
  T,
  formatDate,
  locale,
  moveInWindows = [],
  moveInWindowsTooltip,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(true);

  const onOverlayOpenHandler = React.useCallback(() => {
    if (onOverlayOpen) {
      onOverlayOpen();
    }
    setOpen(true);
  }, [onOverlayOpen]);

  const onOverlayCloseHandler = React.useCallback(() => {
    if (onOverlayClose) {
      onOverlayClose();
    }
    setOpen(false);
  }, [onOverlayClose]);

  const onOverlayExitHandler = React.useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const onDateChangeHandler = React.useCallback(
    (date: Date) => {
      if (onOverlayClose) {
        onOverlayClose();
      }
      const newDate = setDateTimeToNoon(date); // always set noon to avoid time zone issues
      onDateChange(newDate);
      setOpen(false);
    },
    [onDateChange, onOverlayClose],
  );

  const finalInputProps = {
    fullWidth: true,
    'data-test-locator': 'DatePicker / Input',
    ...inputProps,
    label: inputLabel ?? '',
  };

  const isDayDisabled = React.useCallback(
    (day: Date) => {
      if (minDate && isDayBefore(day, new Date(minDate))) {
        return true;
      }

      return !!(maxDate && isDayAfter(day, new Date(maxDate)));
    },
    [minDate, maxDate],
  );

  const finalDatePickerProps = {
    localeString: locale,
    ...datePickerProps,
    selected: value,
    onDayClick: onDateChangeHandler,
    defaultMonth: new Date(),
    modifiers: {
      disabled: isDayDisabled,
      start: value || false,
      end: value || false,
    },
  };

  return (
    <Grid container justifyContent={justifyContent} ref={ref}>
      <SelectDatePickerInput
        inputProps={finalInputProps}
        onInputFocus={onOverlayOpenHandler}
        value={value}
        T={T}
        formatDate={formatDate}
      >
        {({ onInputFocus, inputProps, value }) => (
          <DateInput
            {...inputProps}
            dateValue={value}
            onInputClick={onInputFocus}
            dateInputType="outlined"
            variant="outlined"
            size="small"
          />
        )}
      </SelectDatePickerInput>
      <DatePickerPopover
        containerRef={ref}
        focused={null}
        from={value}
        onOverlayClose={onOverlayCloseHandler}
        onOverlayExit={onOverlayExitHandler}
        open={open}
        OverlayActions={
          <CalendarFooter
            clearDatesButton=""
            onOverlayClose={onOverlayCloseHandler}
            showOverlayDoneButton={false}
            showNoEndDateButton={false}
            overlayDoneBtnLabel=""
            noEndDateButton=""
            buttonProps={{}}
          />
        }
        OverlayInfo={<CalendarInfo additionalInfo={AdditionalInfo} T={T} />}
        T={T}
        formatDate={formatDate}
      >
        <Box maxWidth={350}>
          {pickerCaption}
          <Calendar
            {...finalDatePickerProps}
            mode="default"
            captionSubtitle={CaptionSubtitle}
            moveInWindows={moveInWindows}
            moveInWindowsTooltip={moveInWindowsTooltip ?? ''}
            highlightMoveInWindows
            defaultMonth={new Date(minDate!)}
          />
        </Box>
      </DatePickerPopover>
    </Grid>
  );
};

export { SelectDatePicker };
