import React from 'react';

import { TextFieldProps } from '@hbf/dsl/legacy';

import { DatePickerInputsProps, FOCUSED_FIELD, FormatDate } from '../types';

interface Props {
  children: (childrenProps: DatePickerInputsProps) => JSX.Element;
  focused: FOCUSED_FIELD | null;
  from?: Date;
  fromEmptyValueLabel: string;
  fromInputProps: TextFieldProps;
  onFieldFocusChange: (field: FOCUSED_FIELD) => void;
  onClearDates?: () => void;
  to?: Date;
  toEmptyValueLabel: string;
  toInputProps: TextFieldProps;
  formatDate: FormatDate;
  flexDays?: number;
}

const DateRangePickerInputsWrapper: React.FC<Props> = ({
  children,
  focused,
  from,
  fromEmptyValueLabel,
  fromInputProps,
  onFieldFocusChange,
  onClearDates,
  to,
  toEmptyValueLabel,
  toInputProps,
  formatDate,
  flexDays,
}) => {
  // formatter function
  const formatDateValue = React.useCallback(
    (date: Date | undefined, field: FOCUSED_FIELD) => {
      const defaultByField = {
        [FOCUSED_FIELD.FROM]: fromEmptyValueLabel,
        [FOCUSED_FIELD.TO]: toEmptyValueLabel,
      };

      return date ? formatDate('DD MMM YYYY', date) : defaultByField[field];
    },
    [formatDate, fromEmptyValueLabel, toEmptyValueLabel],
  );

  const childrenProps = {
    from: formatDateValue(from, FOCUSED_FIELD.FROM),
    isFromFocused: focused === FOCUSED_FIELD.FROM,
    isToFocused: focused === FOCUSED_FIELD.TO,
    onFromFocus: () => onFieldFocusChange(FOCUSED_FIELD.FROM),
    onToFocus: () =>
      onFieldFocusChange(from ? FOCUSED_FIELD.TO : FOCUSED_FIELD.FROM),
    to: formatDateValue(to, FOCUSED_FIELD.TO),
    fromInputProps,
    toInputProps,
    onClearDates,
    flexDays,
  };

  return children(childrenProps);
};

export { DateRangePickerInputsWrapper };
