import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ThemeProvider } from '@mui/material';

import { theme } from '@hbf/dsl/legacy';
import {
  DateRangePicker,
  DATEPICKER_VARIANT,
  DateRange,
  FlexDateRange,
} from '@ha/marketplace/feature-date-picker';

import { constants } from 'ha/constants/commonConstants';
import { useIntl } from 'ha/i18n';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';

import { DateRangePickerInputs } from './DateRangePickerInputs';
import { DatePickerFieldValue } from '../../types';

const HomeDateRangePicker: React.FC<FieldRenderProps<DatePickerFieldValue>> = ({
  input,
}) => {
  const { T, formatDate, locale } = useIntl();
  const { sm } = useMediaQuery();

  const { value } = input;

  const datePickerValue: DateRange = React.useMemo(
    () => ({
      from: value.startDate ?? undefined,
      to: value.endDate ?? undefined,
      flexDays: value.flexDays ?? DEFAULT_SEARCH_FLEXIBILITY.Home,
    }),
    [value],
  );

  const handleDateChange = React.useCallback(
    (inputValue: FlexDateRange) => {
      input.onChange({
        startDate: inputValue.from ?? null,
        endDate: inputValue.to ?? null,
        flexDays: inputValue.flexDays ?? null,
      });
    },
    [input],
  );

  return (
    // use the default theme since homepage uses the custom one and overwrites fonts */
    <ThemeProvider theme={theme}>
      <DateRangePicker
        clearDatesButton={T('Reset')}
        DatePickerInputs={DateRangePickerInputs}
        formatDate={formatDate}
        hasFlexibleDatesOption
        hasMoveInMoveOutTitle={sm}
        localeString={locale}
        listingFlexibleDays={constants.listingFlexibleDays}
        noEndDate
        onDateChange={handleDateChange}
        showSingleMonth={!sm}
        value={datePickerValue}
        variant={DATEPICKER_VARIANT.RESPONSIVE}
        T={T}
      />
    </ThemeProvider>
  );
};

export { HomeDateRangePicker };
