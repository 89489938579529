import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid, Typography, units } from '@hbf/dsl/legacy';
import { CalendarLock1 } from '@hbf/icons/streamline-light';

import { TranslateFunc } from '../types';

const useStyles = makeStyles()(theme => ({
  calendarInfoContainer: {
    display: 'block',
    padding: units.trbl(1, 1.25, 1, 1.25),

    [theme.breakpoints.up('sm')]: {
      padding: units.yx(0, 1.5),
    },
  },
  calendarInfoItem: {
    marginBottom: units.rem(0.5),
    [theme.breakpoints.up('sm')]: {
      margin: units.rem(1),
    },
  },
  moveInWindowHintIcon: {
    position: 'relative',
    top: '-1px',
    height: units.rem(0.75),
    width: units.rem(0.75),
    marginRight: units.rem(0.5),
    border: units.border(1, 'solid', theme.palette.secondary.dark),
    borderRadius: '50%',
  },
  periodHintIcon: {
    height: units.rem(1),
    width: units.rem(1),
    margin: units.rem(0.25),
  },
  calendarAdditionalInfo: {
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  moveInWindowHintContainer: {
    paddingBottom: theme.spacing(1),
  },
}));

interface Props {
  additionalInfo?: React.ReactNode;
  minimumPeriod?: number;
  maximumPeriod?: number;
  hasMoveInWindowHint?: boolean;
  T: TranslateFunc;
}

const { Caption } = Typography;

const CalendarInfoInternal: React.FC<Props> = ({
  additionalInfo,
  minimumPeriod,
  hasMoveInWindowHint = false,
  maximumPeriod,
  T,
}) => {
  const { classes } = useStyles();

  const minimumPeriodHint = React.useMemo(
    () =>
      minimumPeriod
        ? T('date_picker.rental_period.min', `${minimumPeriod}-${T('month')}`)
        : '',
    [T, minimumPeriod],
  );

  const maximumPeriodHint = React.useMemo(
    () =>
      maximumPeriod
        ? T('date_picker.rental_period.max', `${maximumPeriod}-${T('month')}`)
        : '',
    [T, maximumPeriod],
  );

  if (!additionalInfo && !minimumPeriodHint && !hasMoveInWindowHint) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.calendarInfoContainer}
    >
      {additionalInfo && (
        <Grid
          item
          className={classes.calendarInfoItem}
          data-test-locator="CalendarInfo / Additional info"
        >
          {additionalInfo}
        </Grid>
      )}
      <Grid item>
        <Grid container className={classes.calendarAdditionalInfo}>
          {hasMoveInWindowHint && (
            <Grid item className={classes.calendarInfoItem}>
              <Grid
                container
                alignItems="center"
                className={classes.moveInWindowHintContainer}
              >
                <Grid item className={classes.moveInWindowHintIcon} />
                <Grid item>
                  <Caption data-test-locator="CalendarInfo / Move-in hint">
                    {T('date_picker.move_in_window.legend_label')}
                  </Caption>
                </Grid>
              </Grid>
            </Grid>
          )}
          {minimumPeriodHint && (
            <Grid item className={classes.calendarInfoItem}>
              <Grid container alignItems="center">
                <CalendarLock1 className={classes.periodHintIcon} />
                <Grid item>
                  <Caption data-test-locator="CalendarInfo / Minimal period hint">
                    {minimumPeriodHint}
                  </Caption>
                </Grid>
              </Grid>
            </Grid>
          )}
          {maximumPeriodHint && (
            <Grid item className={classes.calendarInfoItem}>
              <Grid container alignItems="center">
                <CalendarLock1 className={classes.periodHintIcon} />
                <Grid item>
                  <Caption data-test-locator="CalendarInfo / Minimal period hint">
                    {maximumPeriodHint}
                  </Caption>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const CalendarInfo = React.memo(CalendarInfoInternal);
export { CalendarInfo };
