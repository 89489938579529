import React, { createContext, useContext } from 'react';
import { DateRange } from 'react-day-picker';

import { CaptionSubtitle } from '../types';

interface CalendarContext {
  captionSubtitle?: CaptionSubtitle;
  highlightMoveInWindows?: boolean;
  moveInWindows?: DateRange[];
  moveInWindowsTooltip: string;
  customTooltip?: React.ElementType<React.PropsWithChildren<{ day: Date }>>;
}

const CalendarContext = createContext<CalendarContext>({
  captionSubtitle: undefined,
  highlightMoveInWindows: undefined,
  moveInWindows: undefined,
  moveInWindowsTooltip: '',
  customTooltip: undefined,
});

CalendarContext.displayName = 'CalendarContext';

export const CalendarContextProvider = CalendarContext.Provider;
export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  return context;
};
