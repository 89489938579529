import * as React from 'react';
import { useDayPicker } from 'react-day-picker';
import { makeStyles } from 'tss-react/mui';

import type { Theme } from '@hbf/dsl/legacy';
import { Typography } from '@hbf/dsl/legacy';

import { DAY_COLUMN_SIZE_DESKTOP, DAY_COLUMN_SIZE_MOBILE } from '../constants';
import { getWeekdays } from '../helpers/getWeekdays';

const useStyles = makeStyles()((theme: Theme) => ({
  weekDayBox: {
    color: theme.palette.text.hint,
    height: 24,
    margin: '0 auto',
    textAlign: 'center',
    width: DAY_COLUMN_SIZE_DESKTOP,

    [theme.breakpoints.down('md')]: {
      width: DAY_COLUMN_SIZE_MOBILE,
    },
  },
}));

const WeekDaysInternal: React.FC = () => {
  const {
    classNames,
    styles,
    showWeekNumber,
    locale,
    weekStartsOn,
    ISOWeek,
    formatters: { formatWeekdayName },
    labels: { labelWeekday },
  } = useDayPicker();
  const weekdays = getWeekdays(locale, weekStartsOn, ISOWeek);
  const { classes } = useStyles();

  return (
    <tr style={styles.head_row} className={classNames.head_row}>
      {showWeekNumber && (
        <th
          scope="col"
          style={styles.head_cell}
          className={classNames.head_cell}
        />
      )}
      {weekdays.map((weekday, i) => (
        <th
          key={i}
          scope="col"
          className={classNames.head_cell}
          style={styles.head_cell}
        >
          <span aria-hidden>
            <div
              className={classes.weekDayBox}
              data-test-locator="DatePicker/Calendar/WeekDay"
            >
              <Typography.Caption color="muted">
                {formatWeekdayName(weekday, { locale })}
              </Typography.Caption>
            </div>
          </span>
          <span className={classNames.vhidden}>
            {labelWeekday(weekday, { locale })}
          </span>
        </th>
      ))}
    </tr>
  );
};

const WeekDays = React.memo(WeekDaysInternal);

export { WeekDays };
