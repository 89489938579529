import React from 'react';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

export interface SuggestionArgs<T> {
  item: T;
  highlighted: boolean;
  value: string;
  props: React.HTMLAttributes<HTMLLIElement>;
}

export type MenuItems = React.ReactNode[] | null;

export interface RenderMenuArgs {
  key: string;
  children?: React.ReactNode;
}

type RenderReturnType = React.ReactNode | undefined;

export type RenderInputFn = (
  inputProps: AutocompleteRenderInputParams,
) => RenderReturnType;

type RenderSuggestionFn<T> = (props: SuggestionArgs<T>) => RenderReturnType;

export type RenderMenuFn = (props: RenderMenuArgs) => RenderReturnType;

export interface RequiredProps<T> {
  // this is a render prop for rendering and input
  renderInput: RenderInputFn;
  // this is a render prop for rendering suggestions, they can be simple text, with imgs, etc
  renderSuggestion: RenderSuggestionFn<T>;
  // this is a render prop for rendering the menu, the place where items are rendered
  renderMenu: RenderMenuFn;
  // these are the items that will be rendered with renderSuggestion
  items: T[];
  // when the input changes it calls onChange
  onChange: (value: string) => void;
  // when the input is focused
  onFocus?: () => void;
  // when selection occurs from picking a suggestion it calls onSelect
  onSelect: (value: string, item: T) => void;
  // function for getting menu item value according to the type you are handling
  getItemValue: (value: T) => string;
  clearIcon?: React.ReactNode;
}

interface DefaultProps {
  // value for input passed down from outside
  value: string;
  selectOnBlur?: boolean;
  id?: string;
}

export type Props<T> = RequiredProps<T> & DefaultProps;

class AutocompleteTextInput<T> extends React.Component<
  Props<T> & DefaultProps
> {
  static defaultProps: DefaultProps = { value: '', selectOnBlur: true };

  handleChange = (value: string) => {
    this.props.onChange(value);
  };

  handleSelect = (value: string, item: T) => {
    this.props.onSelect(value, item);
  };

  render() {
    const {
      value,
      getItemValue,
      items,
      renderInput,
      renderMenu,
      renderSuggestion,
      selectOnBlur,
      clearIcon,
    } = this.props;

    return (
      <Autocomplete
        id={this.props.id}
        blurOnSelect={selectOnBlur}
        data-test-locator="Location Autocomplete"
        getOptionLabel={getItemValue}
        inputValue={value}
        isOptionEqualToValue={(option, selectedValue) =>
          getItemValue(option) === getItemValue(selectedValue)
        }
        onChange={(_, changedValue: T) => {
          if (changedValue) {
            this.handleSelect(getItemValue(changedValue), changedValue);
          }
        }}
        onInputChange={(_, inputValue) => {
          this.handleChange(inputValue);
        }}
        filterOptions={options => options}
        options={items}
        onFocus={this.props.onFocus}
        openOnFocus={!!items}
        renderInput={props => renderInput(props)}
        renderOption={(props, item, { inputValue, selected }) =>
          renderSuggestion({
            props,
            item,
            highlighted: selected,
            value: inputValue,
          })
        }
        renderGroup={renderMenu}
        groupBy={() => ''}
        autoHighlight
        freeSolo
        clearIcon={clearIcon || undefined}
      />
    );
  }
}

export { AutocompleteTextInput };
