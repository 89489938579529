import { TranslateFunc } from '@ha/intl';

import { FormValues } from './types';

export function validateForm(values: FormValues, T: TranslateFunc) {
  const errors: {
    [K in keyof FormValues]?: string | undefined;
  } = {};

  if (!values.place) {
    errors.place = T('home_search_bar_error_place_is_required');
  }

  if (values.place && values.place.length <= 2) {
    errors.place = T('Wrong format');
  }

  return errors;
}
