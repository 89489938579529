import {
  TypeValue,
  RoomsValue,
  RegistrationFilterValue,
  BillsFilterValue,
  FurnitureFilterValue,
  RulesFilterValue,
  GenderFilterValue,
  RecentlyAddedFilterValue,
  SuitableForFilterValue,
  SortingValue,
  PriceFilterValue,
  DateFilterValue,
  ContractTypeFilterValue,
  PropertySizeFilterValue,
  FacilitiesFilterValue,
  AmenitiesFilterValue,
  RadiusFilterValue,
  AdvertiserRatingFilterValue,
  RadiusValue,
} from 'ha/types/SearchFilters';

export interface FilterQueryParams {
  page: number | undefined;
  categories: TypeValue[] | undefined;
  advRating: AdvertiserRatingFilterValue | undefined;
  furniture: FurnitureFilterValue | undefined;
  bills: BillsFilterValue | undefined;
  registration: RegistrationFilterValue | undefined;
  suitableFor: SuitableForFilterValue | undefined;
  bedroomCount: RoomsValue[] | undefined;
  rules: RulesFilterValue | undefined;
  gender: GenderFilterValue | undefined;
  recentlyAdded: RecentlyAddedFilterValue | undefined;
  startDate: DateFilterValue['startDate'] | undefined;
  endDate: DateFilterValue['endDate'] | undefined;
  flexDays: DateFilterValue['flexDays'] | undefined;
  currency: string | undefined;
  priceMin: PriceFilterValue['min'] | undefined;
  priceMax: PriceFilterValue['max'] | undefined;
  contractType: ContractTypeFilterValue | undefined;
  sorting: SortingValue | undefined;
  sizeMin: PropertySizeFilterValue['sizeMin'] | undefined;
  sizeMax: PropertySizeFilterValue['sizeMax'] | undefined;
  facilities: FacilitiesFilterValue | undefined;
  amenities: AmenitiesFilterValue | undefined;
  radius: RadiusFilterValue | undefined;
}

export const RequiredAnalyticsFilters = {
  endDate: '',
  startDate: '',
  priceMax: '',
  priceMin: '',
  categories: [],
  furniture: [],
  advRating: [],
  rooms: { bedroomCount: [] },
  bills: [],
  suitableFor: [],
  registration: [],
  recentlyAdded: null,
  rules: [],
  gender: [],
  contractType: [],
  sizeMin: '',
  sizeMax: '',
  facilities: [],
  amenities: [],
  radius: RadiusValue.DEFAULT,
};
