import React from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  units,
} from '@hbf/dsl/legacy';

import { TranslateFunc } from '../../types';

import { FlexibleDaysValues } from '../../constants';

interface Props {
  className?: string;
  flexDays?: number;
  isFlexibleDatesDesktop?: boolean;
  onFlexDaysChange: (flexDays: number) => void;
  T: TranslateFunc;
}

const useStyles = makeStyles()({
  customButton: {
    borderRadius: units.rem(6.25),
    textTransform: 'initial',
  },
  active: {
    fontWeight: 'bold',
  },
  flexibleDatesDesktop: {
    margin: units.yx(0.625, 0),
  },
  radioGroup: {
    width: '100%',
  },
});

const FlexibilityDatesInternal: React.FC<Props> = ({
  className,
  flexDays = FlexibleDaysValues.EXACT,
  isFlexibleDatesDesktop = false,
  onFlexDaysChange,
  T,
}) => {
  const { classes, cx } = useStyles();

  const FlexibilityOptions = React.useMemo(
    () => [
      {
        dataTestLocator: 'FlexDays/exact date',
        title: T('flexible_search_dates.exact_date'),
        value: FlexibleDaysValues.EXACT,
      },
      {
        dataTestLocator: 'FlexDays/1 week',
        title: `± ${T('flexible_search_dates.options.1_week')}`,
        value: FlexibleDaysValues.ONE_WEEK,
      },
      {
        dataTestLocator: 'FlexDays/2 weeks',
        title: `± ${T('flexible_search_dates.options.2_weeks')}`,
        value: FlexibleDaysValues.TWO_WEEKS,
      },
    ],
    [T],
  );

  return (
    <Grid container className={className} data-test-locator="FlexibilityDates">
      <RadioGroup
        aria-label="flexDays"
        name="flexDays"
        value={flexDays}
        onChange={event => onFlexDaysChange(parseInt(event.target.value))}
        className={classes.radioGroup}
      >
        <Grid
          alignItems="center"
          className={isFlexibleDatesDesktop ? classes.flexibleDatesDesktop : ''}
          container
          gap={1}
          item
          justifyContent={isFlexibleDatesDesktop ? 'flex-start' : 'center'}
        >
          {FlexibilityOptions.map(flexOption => (
            <Grid
              item
              key={flexOption.dataTestLocator}
              data-test-locator={flexOption.dataTestLocator}
            >
              <FormControlLabel
                value={flexOption.value}
                control={
                  <Radio
                    checked={flexDays === flexOption.value}
                    sx={{ display: 'none' }}
                    data-test-locator={`${flexOption.dataTestLocator}/input`}
                  />
                }
                label={
                  <Button
                    color="secondary"
                    data-test-locator={`${flexOption.dataTestLocator}/button`}
                    variant={
                      flexDays === flexOption.value ? 'contained' : 'outlined'
                    }
                    disableElevation
                    size="small"
                    className={cx(
                      classes.customButton,
                      flexDays === flexOption.value && classes.active,
                    )}
                    onClick={() => onFlexDaysChange(flexOption.value)}
                  >
                    {flexOption.title}
                  </Button>
                }
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Grid>
  );
};

const FlexibilityDates = React.memo(FlexibilityDatesInternal);
export { FlexibilityDates };
