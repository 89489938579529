import React from 'react';

import { TextFieldProps } from '@hbf/dsl/legacy';

import { DatePickerInputProps, TranslateFunc, FormatDate } from '../types';

interface Props {
  children: (childrenProps: DatePickerInputProps) => JSX.Element;
  inputProps: TextFieldProps;
  onInputFocus: () => void;
  value?: Date;
  T: TranslateFunc;
  formatDate: FormatDate;
}

const DatePickerInput: React.FC<Props> = ({
  children,
  inputProps,
  onInputFocus,
  value,
  T,
  formatDate,
}) => {
  // formatter function
  const formatDateValue = React.useCallback(
    (date: Date | undefined) =>
      date ? formatDate('DD MMM YYYY', date) : T('Select a date'),
    [T, formatDate],
  );

  const childrenProps = {
    value: formatDateValue(value),
    onInputFocus,
    inputProps,
  };

  return children(childrenProps);
};

export { DatePickerInput };
