import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import {
  MoveInSelector,
  DateSelectionOptions,
} from 'ha/pages/Search/components/SearchFilters/MoveInSelector';
import { Box, Grid, Popover, units } from '@hbf/dsl/legacy';

import { SearchBarTextField } from './SearchBarTextField';

const { useState, useCallback, useMemo } = React;

const useStyles = makeStyles()(theme => ({
  moveInBox: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: units.rem(23.125),
    },
    minWidth: units.rem(21.25),
    maxHeight: units.rem(33.125),
  },
}));
export interface Props {
  onClear: () => void;
  onChange?: (date: string) => void;
}

export const HomeMoveInSelector: React.FC<FieldRenderProps<Props>> = ({
  onClear,
  input,
  onChange,
}) => {
  const { T, formatDateTime } = useIntl();

  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dateType, setDateType] = useState<DateSelectionOptions>('date');
  const [dateValue, setDateValue] = useState('');

  // Date format according to the type of filter. when a user selects hole month it formats like "Jun 2022"
  // But if user selects a specific date it would be the exact date, 21 Jun 2022
  const dateFormat: Intl.DateTimeFormatOptions = useMemo(
    () =>
      dateType === 'month'
        ? { month: 'short', year: 'numeric' }
        : { dateStyle: 'medium' },
    [dateType],
  );

  const removeAnchorElement = useCallback(() => setAnchorEl(null), []);

  const handleChange = (date: string, type: DateSelectionOptions) => {
    setDateType(type);
    input.onChange(date);
    setDateValue(date);
    removeAnchorElement();
    if (onChange) onChange(date);
  };

  const setAnchorElement = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    event => setAnchorEl(event.currentTarget),
    [],
  );

  return (
    <Grid item xs={12} data-test-locator="Search Availability">
      <SearchBarTextField
        autoComplete="off"
        fullWidth
        value={dateValue ? formatDateTime(new Date(dateValue), dateFormat) : ''}
        onClick={setAnchorElement}
        id="move-in-filter"
        placeholder={T('search.filters.move_in.placeholder')}
      />

      <Popover
        anchorEl={anchorEl}
        onClose={removeAnchorElement}
        open={Boolean(anchorEl)}
        PaperProps={{ elevation: 10 }}
      >
        <Box className={classes.moveInBox}>
          <MoveInSelector
            handleSelectedDate={handleChange}
            value={dateValue}
            onClear={onClear}
          />
        </Box>
      </Popover>
    </Grid>
  );
};
