import { Locale as DateFnLocale } from 'date-fns';
import {
  enGB,
  fr,
  de,
  es,
  it,
  nl,
  pt,
  zhCN,
  uk,
  ru,
  pl,
  sv,
  ro,
} from 'date-fns/locale';

import { Locale } from '@ha/intl';

export const getDateFnLocale = (locale?: string): DateFnLocale => {
  switch (locale) {
    case Locale.en:
      return enGB;
    case Locale.fr:
      return fr;
    case Locale.de:
      return de;
    case Locale.es:
      return es;
    case Locale.it:
      return it;
    case Locale.nl:
      return nl;
    case Locale.pt:
      return pt;
    case Locale.zh:
      return zhCN;
    case Locale.uk:
      return uk;
    case Locale.ru:
      return ru;
    case Locale.pl:
      return pl;
    case Locale.sv:
      return sv;
    case Locale.ro:
      return ro;
    default:
      return enGB;
  }
};
