import { FlexibleDaysValues } from '@ha/marketplace/feature-date-picker';
import { HOME, SEARCH } from './pageNames';

export const DEFAULT_SEARCH_FLEXIBILITY = {
  [HOME]: FlexibleDaysValues.TWO_WEEKS,
  [SEARCH]: FlexibleDaysValues.EXACT,
};
export { FlexibleDaysValues };

export const FlexibilityAnalyticsLabel = {
  [FlexibleDaysValues.EXACT]: 'exact',
  [FlexibleDaysValues.ONE_WEEK]: '1 week',
  [FlexibleDaysValues.TWO_WEEKS]: '2 weeks',
} as const;
