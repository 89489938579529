import React from 'react';

import { Button, Grid } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

interface Props {
  handleSelectedDate: () => void;
  onClear: () => void;
}

export const MoveInSelectorActionButtons: React.FC<Props> = ({
  handleSelectedDate,
  onClear,
}) => {
  const { T } = useIntl();

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <Button variant="text" size="large" color="secondary" onClick={onClear}>
          {T('cta.clear')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSelectedDate}
        >
          {T('cta.confirm')}
        </Button>
      </Grid>
    </Grid>
  );
};
