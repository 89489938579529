import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  IconButton,
  InputAdornment,
  InputLabel,
  units,
  TextField,
  TextFieldProps,
} from '@hbf/dsl/legacy';
import { CalendarToday } from '@hbf/icons/brand-regular';
import { Delete2 } from '@hbf/icons/streamline-light';

interface OwnProps {
  dateValue: string;
  onClear?: () => void;
  onInputClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  dateInputType?: 'regular' | 'outlined';
}

const useStyles = makeStyles()(theme => ({
  inputLabel: {
    color: theme.palette.grey[700],
    fontFamily: theme.typography.fontFamily,
    fontSize: units.rem(0.875),
    lineHeight: units.rem(1.125),
    marginBottom: units.rem(0.25),
  },
  icon: {
    width: units.rem(1),
    height: units.rem(1),
    color: theme.palette.grey[700],
  },
}));

export type Props = OwnProps &
  TextFieldProps & { 'data-test-locator'?: string };

const DateInput: React.FC<Props> = props => {
  const { classes } = useStyles();

  const {
    dateValue,
    label,
    onClear,
    onInputClick,
    disabled = false,
    focused = false,
    dateInputType = 'regular',
    ...rest
  } = props;

  const onClearButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClear) {
        onClear();
      }
      event.stopPropagation();
    },
    [onClear],
  );

  const inputLabel = label as string;
  const dateInputId = inputLabel
    ? inputLabel.replace(' ', `${/-/g}`).toLowerCase()
    : '';

  const inputProps = {
    'data-test-locator': props['data-test-locator']
      ? `${props['data-test-locator']} / Input`
      : '',
  };

  const clearButtonLocator = props['data-test-locator']
    ? `${props['data-test-locator']} / Clear Button`
    : '';

  const RegularAdornment = (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        disabled={disabled}
        onClick={disabled ? undefined : onClearButtonClick}
        data-test-locator={clearButtonLocator}
        size="large"
      >
        <Delete2 fontSize="small" />
      </IconButton>
    </InputAdornment>
  );

  const OutlinedAdornment = (
    <InputAdornment position="end">
      <CalendarToday className={classes.icon} />
    </InputAdornment>
  );

  const endAdornment = React.useMemo(() => {
    if (dateInputType === 'outlined') {
      return OutlinedAdornment;
    }

    return onClear ? RegularAdornment : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInputType, onClear]);

  return (
    <React.Fragment>
      {dateInputType === 'outlined' && (
        <InputLabel shrink htmlFor={dateInputId} className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      <TextField
        id={dateInputId}
        fullWidth
        value={dateValue}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment,
        }}
        inputProps={inputProps}
        onClick={disabled ? undefined : onInputClick}
        focused={focused}
        label={dateInputType === 'regular' ? label : undefined}
        {...rest}
      />
    </React.Fragment>
  );
};

export { DateInput };
