import React from 'react';

import { Grid, Typography } from '@hbf/dsl/legacy';
import { FOCUSED_FIELD, TranslateFunc } from '../../types';

interface Props {
  className?: string;
  focusField: FOCUSED_FIELD | null;
  T: TranslateFunc;
}

const MoveInMoveOutInternal: React.FC<Props> = ({
  className = '',
  focusField,
  T,
}) => {
  const title =
    focusField === FOCUSED_FIELD.FROM
      ? T('flexible_search_dates.header_move_in')
      : T('flexible_search_dates.header_move_out');

  return (
    <Grid container className={className}>
      <Grid item>
        <Typography.H4>{title}</Typography.H4>
      </Grid>
    </Grid>
  );
};

const MoveInMoveOutTitle = React.memo(MoveInMoveOutInternal);
export { MoveInMoveOutTitle };
