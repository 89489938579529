import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useIntl } from 'ha/i18n';
import { makeStyles } from 'tss-react/mui';

import { Grid, Popover, MenuItem, units } from '@hbf/dsl/legacy';

import { SearchBarTextField } from './SearchBarTextField';

const { useCallback, useState, useMemo } = React;

const useStyles = makeStyles()({
  defaultValueOption: {
    display: 'none',
  },
  menuPaper: {
    maxHeight: units.rem(20),
  },
  menuItem: {
    width: units.rem(14.375),
  },
});

export const HomeDurationSelector: React.FC<FieldRenderProps<void>> = ({
  input,
}) => {
  const { T } = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [duration, setDuration] = useState<string>();

  const { classes } = useStyles();

  const setAnchorElement = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    event => setAnchorEl(event.currentTarget),
    [],
  );
  const removeAnchorElement = useCallback(() => setAnchorEl(null), []);

  const handleChange = useCallback(
    (inputValue: (typeof items)[0]) => {
      input.onChange(inputValue.value);
      setDuration(inputValue.label);
      removeAnchorElement();
    },
    [input, removeAnchorElement],
  );

  const items = useMemo(
    () =>
      [...Array(12)].map((_, index) => ({
        value: index + 1,
        label: index === 0 ? T('%d month', 1) : T('%d months', index + 1),
      })),
    [T],
  );

  return (
    <Grid item xs={12}>
      <SearchBarTextField
        fullWidth
        placeholder={T('Duration')}
        onClick={setAnchorElement}
        value={duration}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={removeAnchorElement}
        open={Boolean(anchorEl)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{ elevation: 10 }}
        className={classes.menuPaper}
      >
        <MenuItem value={0} disabled className={classes.defaultValueOption}>
          {T('Select period')}
        </MenuItem>
        {items.map(item => (
          <MenuItem
            style={{ width: units.rem(10) }}
            key={item.value}
            value={item.value}
            onClick={() => handleChange(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Popover>
    </Grid>
  );
};
