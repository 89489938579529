import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { DateInput } from '../DateInput/DateInput';
import { DatePickerInputsProps } from '../types';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  input: {
    width: '47%',
  },
});
const DateRangePickerInputsNarrow: React.FC<DatePickerInputsProps> = props => {
  const { classes } = useStyles();
  const {
    from,
    fromInputProps,
    isFromFocused,
    onFromFocus,
    to,
    toInputProps,
    isToFocused,
    onToFocus,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <DateInput
          {...fromInputProps}
          dateValue={from}
          onInputClick={onFromFocus}
          focused={isFromFocused}
        />
      </div>
      <div className={classes.input}>
        <DateInput
          {...toInputProps}
          dateValue={to}
          onInputClick={onToFocus}
          focused={isToFocused}
        />
      </div>
    </div>
  );
};

export { DateRangePickerInputsNarrow };
