import React from 'react';

import { Grid, Typography } from '@hbf/dsl/legacy';
import { FOCUSED_FIELD, TranslateFunc } from '../../types';

interface Props {
  className?: string;
  focusField: FOCUSED_FIELD | null;
  advertiserName?: string;
  minimumPeriod?: number;
  listingType?: string;
  T: TranslateFunc;
}

const MoveInMoveOutInternal: React.FC<Props> = ({
  className = '',
  focusField,
  advertiserName,
  minimumPeriod,
  listingType,
  T,
}) => {
  let subTitle;
  if (focusField === FOCUSED_FIELD.FROM) {
    subTitle = T('date_picker.%s_set_move_in_dates', advertiserName);
  } else if (focusField === FOCUSED_FIELD.TO && !!minimumPeriod) {
    subTitle = T(
      'date_picker.%s_has_min_period_%s',
      listingType,
      `${minimumPeriod}-${T('months')}`,
    );
  }
  return (
    <Grid container className={className}>
      <Grid item>
        <Typography.Caption>{subTitle}</Typography.Caption>
      </Grid>
    </Grid>
  );
};

const MoveInMoveOutSubtitle = React.memo(MoveInMoveOutInternal);
export { MoveInMoveOutSubtitle };
