import { Delete2, Pin } from '@hbf/icons/streamline-light';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { FieldRenderProps } from 'react-final-form';

import { IconButton, units, InputAdornment } from '@hbf/dsl/legacy';
import { getInitialItems } from 'ha/modules/SavedSearches/selectors';
import { MIN_SEARCH_INPUT_LENGTH, Locations } from 'ha/modules/Locations';

import { SearchBarTextField } from './SearchBarTextField';

interface Props {
  id: string;
  label?: string;
  dataTestLocator?: string;
  disabled?: boolean;
  predictionTypes: string[];
  name: string;
  required: boolean;
  placeholder?: string;
}

const useStyles = makeStyles()(theme => ({
  icon: {
    marginLeft: units.rem(1),
    marginRight: units.rem(-0.5),
  },
  inputRoot: {
    paddingRight: '0 !important',
  },
  input: {
    '&&': {
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: units.rem(2),
        lineHeight: 2,
        minHeight: '100%',
      },
    },
  },
}));

export const LocationsInput: React.FC<FieldRenderProps<string> & Props> = ({
  input,
  meta,
  predictionTypes,
  label = '',
  id,
  dataTestLocator,
  disabled,
  name,
  placeholder,
  required,
  searchInputRef,
}) => {
  const { classes } = useStyles();
  const initialItems = useSelector(getInitialItems);
  const [renderInitialItems, setRenderInitialItems] =
    React.useState<boolean>(false);

  const handleFocus = React.useCallback(() => {
    // Show initial suggestions on input focus
    if (input.value === '') {
      setRenderInitialItems(true);
    }

    input.onFocus();
  }, [input]);

  const handleChange = React.useCallback(
    (value: string) => {
      // User erased all symbols, lets show initial suggestions
      if (value.length === 0) {
        setRenderInitialItems(true);
      }

      // User typed MIN_SEARCH_INPUT_LENGTH letters
      // lets hide initial suggestions and show ones from google
      if (
        value.length >= MIN_SEARCH_INPUT_LENGTH &&
        renderInitialItems === true
      ) {
        setRenderInitialItems(false);
      }

      // ignore undefine, string, value that occurs when the user presses enter
      // key without the suggestions to have been loaded.
      if (value === 'undefined') {
        return;
      }

      input.onChange(value);
    },
    [input, renderInitialItems],
  );

  const handleSelect = React.useCallback(
    (value: string) => {
      setRenderInitialItems(false);

      input.onChange(value);
    },
    [input],
  );

  return (
    <Locations
      id={id}
      renderInput={params => {
        return (
          <SearchBarTextField
            {...params}
            fullWidth
            name={name}
            label={label}
            disabled={disabled}
            autoComplete="off"
            required={required}
            placeholder={placeholder}
            data-test-locator={dataTestLocator}
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              ...(searchInputRef ? { inputRef: searchInputRef } : {}),
              startAdornment: (
                <InputAdornment position="start" className={classes.icon}>
                  <Pin />
                </InputAdornment>
              ),
              endAdornment: params.inputProps.value && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      handleChange('');
                    }}
                    size="large"
                  >
                    <Delete2 />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
            }}
          />
        );
      }}
      value={input.value}
      predictionTypes={predictionTypes}
      onSelect={handleSelect}
      onFocus={handleFocus}
      onChange={handleChange}
      renderInitialItems={renderInitialItems}
      initialItems={initialItems}
    />
  );
};
