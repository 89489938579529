import React from 'react';
import { Grid } from '@hbf/dsl/legacy';

import { DateInput } from '../DateInput/DateInput';

import { DatePickerInputsProps } from '../types';

const DateRangePickerInputs: React.FC<DatePickerInputsProps> = ({
  from,
  fromInputProps,
  isFromFocused,
  onFromFocus,
  to,
  toInputProps,
  isToFocused,
  onToFocus,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={5} md={5}>
        <DateInput
          {...fromInputProps}
          dateValue={from}
          onInputClick={onFromFocus}
          focused={isFromFocused}
        />
      </Grid>
      <Grid item xs={2} md={2} />
      <Grid item xs={5} md={5}>
        <DateInput
          {...toInputProps}
          dateValue={to}
          onInputClick={onToFocus}
          focused={isToFocused}
        />
      </Grid>
    </React.Fragment>
  );
};

export { DateRangePickerInputs };
