import React from 'react';
import { Grid } from '@hbf/dsl/legacy';

import { DateInput } from '../DateInput/DateInput';

import { DatePickerInputsProps } from '../types';

const DateRangePickerInputsOutlined: React.FC<DatePickerInputsProps> = ({
  from,
  fromInputProps,
  isFromFocused,
  onFromFocus,
  to,
  toInputProps,
  isToFocused,
  onToFocus,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={5} md={6}>
        <DateInput
          {...fromInputProps}
          dateValue={from}
          onInputClick={onFromFocus}
          focused={isFromFocused}
          dateInputType="outlined"
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={5} md={6}>
        <DateInput
          {...toInputProps}
          dateValue={to}
          onInputClick={onToFocus}
          focused={isToFocused}
          dateInputType="outlined"
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export { DateRangePickerInputsOutlined };
