import React from 'react';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import { Button, ButtonProps, Grid, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()((theme: Theme) => ({
  calendarFooterContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: units.rem(1),
    },
  },
}));

interface Props {
  buttonProps: ButtonProps;
  clearDatesButton?: string;
  overlayDoneBtnLabel: string;
  overlayCancelBtnLabel?: string;
  noEndDateButton: string;
  onClearDates?: () => void;
  onNoEndDate?: () => void;
  onOverlayClose?: () => void;
  onOverlayDone?: () => void;
  showOverlayDoneButton: boolean;
  showNoEndDateButton: boolean;
  showCancelButton?: boolean;
  fullScreenDialog?: boolean;
}

const CalendarFooterInternal: React.FC<Props> = props => {
  const {
    buttonProps,
    clearDatesButton = '',
    onClearDates,
    onNoEndDate,
    onOverlayClose,
    onOverlayDone,
    overlayCancelBtnLabel,
    overlayDoneBtnLabel,
    noEndDateButton,
    showOverlayDoneButton,
    showNoEndDateButton,
    showCancelButton = false,
    fullScreenDialog = false,
  } = props;

  const {
    classes: { calendarFooterContainer },
  } = useStyles();

  return (
    <Grid
      container
      data-test-locator="DatePicker / CalendarFooter"
      justifyContent={fullScreenDialog ? 'space-evenly' : 'flex-end'}
      spacing={1}
      className={calendarFooterContainer}
    >
      {onClearDates && clearDatesButton && (
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            {...buttonProps}
            onClick={onClearDates}
          >
            {clearDatesButton}
          </Button>
        </Grid>
      )}
      {showCancelButton && onOverlayClose && overlayCancelBtnLabel && (
        <Grid item xs={fullScreenDialog ? true : 'auto'}>
          <Button
            color="primary"
            variant="outlined"
            {...buttonProps}
            onClick={onOverlayClose}
            fullWidth={fullScreenDialog}
            data-test-locator="DatePicker Footer - Close Button"
          >
            {overlayCancelBtnLabel}
          </Button>
        </Grid>
      )}
      {showOverlayDoneButton && !showNoEndDateButton && (
        <Grid item xs={fullScreenDialog ? true : 'auto'}>
          <Button
            color="primary"
            variant="contained"
            {...buttonProps}
            onClick={onOverlayDone}
            fullWidth={fullScreenDialog}
            data-test-locator="DatePicker Footer - Done Button"
          >
            {overlayDoneBtnLabel}
          </Button>
        </Grid>
      )}
      {showNoEndDateButton && (
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            {...buttonProps}
            onClick={onNoEndDate}
          >
            {noEndDateButton}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const CalendarFooter = React.memo(CalendarFooterInternal);
export { CalendarFooter };
