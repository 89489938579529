import { addMonths, diffDay } from '@ha/date';

import { isDayExcluded } from './isDayExcluded';
import { isDayAfter } from './isDayAfter';

import { DateSelectionRules, DateRange } from '../types';
import { isDayBefore } from './isDayBefore';

const isFromDateDisabled = (
  day: Date,
  exclusions: DateRange[],
  minimumPeriod: number,
  dateSelectionRules: DateSelectionRules = {},
): boolean => {
  const { minDate, maxDate } = dateSelectionRules;
  if (minDate && isDayBefore(day, new Date(minDate))) {
    return true;
  }
  if (maxDate && isDayAfter(day, new Date(maxDate))) {
    return true;
  }

  // current day is excluded
  if (isDayExcluded(day, exclusions)) {
    return true;
  }

  const minimalPeriodDays =
    diffDay(addMonths(day.toISOString(), minimumPeriod), day) - 1;

  return exclusions.some(({ from }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const diff = diffDay(from, day);

    return diff >= 0 && diff <= minimalPeriodDays;
  });
};

export { isFromDateDisabled };
