import {
  DayPickerProps as DayPickerPropsType,
  DayPickerRangeProps as DayPickerRangePropsType,
  DateRange as DateRangeType,
} from 'react-day-picker';

import { ButtonProps, TextFieldProps, GridProps } from '@hbf/dsl/legacy';

export type DayPickerProps = DayPickerPropsType;
export type DateRange = DateRangeType;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DATEPICKER_VARIANT {
  INLINE = 'inline',
  DIALOG = 'dialog',
  RESPONSIVE = 'responsive',
}

export type CaptionSubtitle = (currMonth: Date) => React.ReactNode;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslateFunc = (key: string, ...args: any[]) => string;

export type FormatDate = (
  format: string,
  date: Date | string | undefined,
) => string;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FOCUSED_FIELD {
  FROM = 'from',
  TO = 'to',
}

export type FlexDateRange = DateRangeType & {
  flexDays?: number;
};

export interface OverlayProps {
  children: React.ReactElement<DayPickerProps>;
  containerRef: React.RefObject<HTMLDivElement>;
  dialogTitle?: string;
  FlexibleDatesOption?: React.ReactNode;
  focused: FOCUSED_FIELD | null;
  formatDate: FormatDate;
  from?: Date;
  fullScreenDialog?: boolean;
  MoveInMoveOutTitle?: React.ReactNode;
  MoveInMoveOutSubtitle?: React.ReactNode;
  onFieldFocusChange: (field: FOCUSED_FIELD) => void;
  onOverlayClose: () => void;
  onOverlayDone?: () => void;
  onOverlayExit: () => void;
  open: boolean;
  OverlayActions: React.ReactNode;
  OverlayInfo: React.ReactNode;
  OverlayTitle?: React.ReactNode;
  to?: Date;
  T: TranslateFunc;
  withoutDividers?: boolean;
}

export interface DatePickerInputsProps {
  from: string;
  fromInputProps: TextFieldProps;
  toInputProps: TextFieldProps;
  to: string;
  isFromFocused: boolean;
  isToFocused: boolean;
  onFromFocus: () => void;
  onToFocus: () => void;
  onClearDates?: () => void;
}

export interface DateRangePickerProps {
  autoClose?: boolean;
  AdditionalInfo?: React.ReactNode;
  CaptionSubtitle?: (currMonth: Date) => React.ReactNode;
  DatePickerInputs?: React.ComponentType<DatePickerInputsProps>;
  flexibleDatesClassName?: string;
  value: FlexDateRange;
  variant?: DATEPICKER_VARIANT;
  clearDatesButton?: string;
  fromInputLabel?: string;
  toInputLabel?: string;
  fromEmptyValueLabel?: string;
  toEmptyValueLabel?: string;
  exclusions?: DateRange[];
  datePickerProps?: Partial<DayPickerRangePropsType>;
  initialValidation?: boolean;
  fromInputProps?: TextFieldProps;
  toInputProps?: TextFieldProps;
  onBlur?: () => void;
  onDateChange: (range: FlexDateRange) => void;
  minimumPeriod?: number;
  moveInWindows?: DateRange[];
  moveInWindowsTooltip?: string;
  noEndDate?: boolean;
  noEndDateLabel?: string;
  overlayDoneBtnLabel?: string;
  buttonProps?: ButtonProps;
  required?: boolean;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  header?: React.ReactNode;
  showSingleMonth: boolean;
  T: TranslateFunc;
  localeString: string;
  listingFlexibleDays: number;
  formatDate: FormatDate;
  withoutDividers?: boolean;
  dialogTitle?: string;
  onOverlayDone?: (range: DateRange) => void;
  onOverlayCancel?: () => void;
  onOverlayExit?: () => void;
  fullScreenDialog?: boolean;
  showCancelButton?: boolean;
  maximumStay?: number;
  hasFlexibleDatesOption?: boolean;
  hasMoveInMoveOutTitle?: boolean;
  hasMoveInMoveOutSubtitle?: boolean;
  moveInMoveOutClassName?: string;
  advertiserName?: string;
  listingType?: string;
}

export interface DateSelectionRules {
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
}

export interface DatePickerInputProps {
  inputProps: TextFieldProps;
  onInputFocus: () => void;
  value: string;
}

export interface DatePickerProps {
  AdditionalInfo?: React.ReactNode;
  CaptionSubtitle?: DateRangePickerProps['CaptionSubtitle'];
  clearDateButton?: string;
  DatePickerInput?: React.ComponentType<DatePickerInputProps>;
  datePickerProps?: DayPickerProps;
  inputLabel?: string | React.ReactNode;
  inputProps?: TextFieldProps;
  justifyContent?: GridProps['justifyContent'];
  maxDate?: Date | string | null;
  minDate?: Date | string | null;
  onBlur?: () => void;
  onDateChange: (date: Date | undefined) => void;
  onOverlayClose?: () => void;
  onOverlayOpen?: () => void;
  pickerCaption?: React.ReactNode;
  value?: Date;
  T: TranslateFunc;
  formatDate: FormatDate;
  locale: string;
}

export interface SelectDatePickerProps extends DatePickerProps {
  moveInWindows?: DateRange[];
  moveInWindowsTooltip?: string;
}
