import dayjs from 'dayjs';
import { isDayBetween } from '@ha/date';
import { isDayExcluded } from './isDayExcluded';
import { isDayAfter } from './isDayAfter';
import { isDayBefore } from './isDayBefore';

import { calcFirstAvailableToDate } from './calcFirstAvailableToDate';

import { DateSelectionRules, DateRange } from '../types';

const isToDateDisabled = (
  listingFlexibleDays: number,
  day: Date,
  from?: Date,
  exclusions: DateRange[] = [],
  minimumPeriod = 0,
  maximumStay = 0,
  dateSelectionRules: DateSelectionRules = {},
): boolean => {
  const { minDate, maxDate } = dateSelectionRules;

  if (minDate && isDayBefore(day, new Date(minDate))) {
    return true;
  }

  if (maxDate && isDayAfter(day, new Date(maxDate))) {
    return true;
  }

  if (isDayExcluded(day, exclusions)) {
    return true;
  }

  // current day overlaps one of exclusions' periods
  const hasOverlap = exclusions.some(
    (excl: DateRange) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      from && dayjs(day).isAfter(from) && isDayBetween(from, day, excl.from),
  );

  if (hasOverlap) {
    return true;
  }

  if (from && minimumPeriod) {
    const minimumAvailableDay = calcFirstAvailableToDate(
      from,
      minimumPeriod,
      listingFlexibleDays,
    );

    if (isDayBefore(day, minimumAvailableDay)) {
      return true;
    }
  }

  // maximumStay: maximum period in months booking can be rented for. Can be 0 (no
  // limit), 0.25 (equals to 1 week), 0.5 (equals to 2 weeks), 0.75 (equals to 3 weeks)
  // and any integer >= 1 that will represent number of months
  if (from && maximumStay > 0) {
    let dateFrom;
    if (maximumStay < 1) {
      const weeks = Math.floor(maximumStay / 0.25);
      dateFrom = dayjs(from).add(weeks, 'week');
    } else {
      dateFrom = dayjs(from).add(maximumStay, 'month');
    }
    if (isDayBefore(new Date(dateFrom.subtract(1, 'day').toISOString()), day)) {
      return true;
    }
  }

  return false;
};

export { isToDateDisabled };
