import React from 'react';
import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { normalizeDate } from '@ha/date';
import { Grid, Typography } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { DateSelectionOptions } from './types';
import { MoveInSelectorContent } from './MoveInSelectorContent';
import { MoveInSelectorActionButtons } from './MoveInSelectorActionButtons';

export * from './types';

const { useState } = React;

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

export interface Props {
  handleSelectedDate: (
    selectedDate: string,
    type: DateSelectionOptions,
  ) => void;
  onClear: () => void;
  value?: string;
}

export const MoveInSelector: React.FC<Props> = ({
  handleSelectedDate,
  onClear,
  value,
}) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  const [dateType, setDateType] = useState<DateSelectionOptions>('date');

  const [selectedDate, setSelectedDate] = React.useState<Date>(
    value ? new Date(value) : new Date(),
  );

  const onChange = (date: Date, type: DateSelectionOptions) => {
    setSelectedDate(date);
    setDateType(type);
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.header}>
        <Typography.H4 data-test-locator="MoveInSelector title">
          {T('date_picker.move_in.title')}
        </Typography.H4>
      </Grid>
      <Grid item className={classes.content}>
        <MoveInSelectorContent
          selectedDate={selectedDate}
          setSelectedDate={onChange}
        />
      </Grid>
      <Grid item>
        <MoveInSelectorActionButtons
          handleSelectedDate={() =>
            handleSelectedDate(normalizeDate(selectedDate), dateType)
          }
          onClear={onClear}
        />
      </Grid>
    </Grid>
  );
};
