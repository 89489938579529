import isEqual from 'lodash-es/isEqual';
import pickBy from 'lodash-es/pickBy';

import { RequiredAnalyticsFilters } from './types';

export const getFilterTypesForAnalytics = (
  filters: Record<string, unknown> | undefined = {},
) => {
  const filterTypesWithValues = pickBy(
    filters,
    (val, key) => !isEqual(val, RequiredAnalyticsFilters[key]),
  );
  return {
    filterTypes: Object.keys(filterTypesWithValues),
    filterTypesWithValues,
  };
};
