import React from 'react';

import { ButtonProps, Grid, TextFieldProps } from '@hbf/dsl/legacy';

import { DateRangePickerInputsWrapper } from './DateRangePickerInputsWrapper';
import { Calendar } from '../Calendar/Calendar';
import { CalendarFooter } from '../Calendar/CalendarFooter';
import { CalendarInfo } from '../Calendar/CalendarInfo';
import {
  DatePickerInputsProps,
  DateRangePickerProps,
  FOCUSED_FIELD,
  OverlayProps,
  DayPickerProps,
  TranslateFunc,
  FormatDate,
  DateRange,
} from '../types';
import { FlexibilityDates } from '../Overlays';
import { MoveInMoveOutSubtitle } from '../Overlays/FlexibilityDates/MoveInMoveOutSubtitle';
import { MoveInMoveOutTitle } from '../Overlays/FlexibilityDates/MoveInMoveOutTitle';

interface Props {
  AdditionalInfo?: React.ReactNode;
  autoClose: boolean;
  buttonProps: ButtonProps;
  noEndDate: boolean;
  noEndDateLabel: string;
  CaptionSubtitle?: DateRangePickerProps['CaptionSubtitle'];
  DatePickerInputs: React.ComponentType<DatePickerInputsProps>;
  datePickerProps: DayPickerProps;
  flexDays?: number;
  flexibleDatesClassName?: string;
  focused: FOCUSED_FIELD | null;
  from?: Date;
  fromEmptyValueLabel: string;
  to?: Date;
  onDatesClear?: () => void;
  onFlexDaysChange: (flexDays: number) => void;
  onNoEndDate: () => void;
  onOverlayExit: () => void;
  onOverlayOpen: (field: FOCUSED_FIELD) => void;
  overlayDoneBtnLabel: string;
  clearDatesButton?: string;
  fromInputProps: TextFieldProps;
  minimumPeriod?: number;
  maximumPeriod?: number;
  moveInWindows: DateRange[];
  moveInWindowsTooltip: string;
  toInputProps: TextFieldProps;
  toEmptyValueLabel: string;
  Overlay: React.FC<OverlayProps>;
  header?: React.ReactNode;
  T: TranslateFunc;
  formatDate: FormatDate;
  withoutDividers: boolean;
  dialogTitle?: string;
  onOverlayDone?: (range: DateRange) => void;
  onOverlayCancel?: () => void;
  fullScreenDialog?: boolean;
  showCancelButton?: boolean;
  localeString: string;
  hasFlexibleDatesOption?: boolean;
  hasMoveInMoveOutTitle?: boolean;
  hasMoveInMoveOutSubTitle?: boolean;
  advertiserName?: string;
  listingType?: string;
  isDesktop?: boolean;
}

const DateRangePickerOverlay: React.FC<Props> = ({
  AdditionalInfo,
  autoClose,
  buttonProps,
  CaptionSubtitle,
  clearDatesButton,
  DatePickerInputs,
  datePickerProps,
  dialogTitle,
  flexDays,
  flexibleDatesClassName,
  focused,
  formatDate,
  from,
  fromInputProps,
  fromEmptyValueLabel,
  fullScreenDialog,
  hasFlexibleDatesOption = false,
  hasMoveInMoveOutTitle = false,
  hasMoveInMoveOutSubTitle = false,
  isDesktop = false,
  header,
  maximumPeriod,
  minimumPeriod,
  moveInWindows,
  moveInWindowsTooltip,
  noEndDate,
  noEndDateLabel,
  onDatesClear,
  onFlexDaysChange,
  onNoEndDate,
  onOverlayExit,
  onOverlayOpen,
  Overlay,
  overlayDoneBtnLabel,
  onOverlayDone,
  onOverlayCancel,
  showCancelButton,
  T,
  to,
  toInputProps,
  toEmptyValueLabel,
  withoutDividers,
  localeString,
  advertiserName,
  listingType,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (open && !focused && autoClose) {
      setOpen(false);
    }
  }, [autoClose, focused, open]);

  const onOverlayOpenHandler = React.useCallback(
    (field: FOCUSED_FIELD) => {
      setOpen(true);
      onOverlayOpen(field);
    },
    [onOverlayOpen],
  );

  const onOverlayCloseHandler = React.useCallback(() => {
    if (onOverlayCancel) {
      onOverlayCancel();
    }
    setOpen(false);
  }, [onOverlayCancel]);

  const onOverlayDoneHandler = React.useCallback(() => {
    if (onOverlayDone) {
      onOverlayDone({ from, to });
    }
    setOpen(false);
  }, [onOverlayDone, from, to]);

  const onNoEndDateHandler = React.useCallback(() => {
    onNoEndDate();
    setOpen(false);
  }, [onNoEndDate]);

  return (
    <Grid container justifyContent="center" ref={ref}>
      <DateRangePickerInputsWrapper
        focused={focused}
        formatDate={formatDate}
        from={from}
        fromEmptyValueLabel={fromEmptyValueLabel}
        fromInputProps={fromInputProps}
        onClearDates={onDatesClear}
        onFieldFocusChange={onOverlayOpenHandler}
        to={to}
        toEmptyValueLabel={toEmptyValueLabel}
        toInputProps={toInputProps}
        flexDays={flexDays}
      >
        {inputsProps => <DatePickerInputs {...inputsProps} />}
      </DateRangePickerInputsWrapper>
      <Overlay
        T={T}
        containerRef={ref}
        dialogTitle={dialogTitle}
        MoveInMoveOutTitle={
          hasMoveInMoveOutTitle && (
            <MoveInMoveOutTitle focusField={focused} T={T} />
          )
        }
        MoveInMoveOutSubtitle={
          hasMoveInMoveOutSubTitle && (
            <MoveInMoveOutSubtitle
              focusField={focused}
              T={T}
              advertiserName={advertiserName}
              minimumPeriod={minimumPeriod}
              listingType={listingType}
            />
          )
        }
        FlexibleDatesOption={
          hasFlexibleDatesOption && (
            <FlexibilityDates
              className={flexibleDatesClassName}
              flexDays={flexDays}
              isFlexibleDatesDesktop={isDesktop}
              onFlexDaysChange={onFlexDaysChange}
              T={T}
            />
          )
        }
        focused={focused}
        formatDate={formatDate}
        from={from}
        fullScreenDialog={fullScreenDialog}
        onFieldFocusChange={onOverlayOpen}
        onOverlayExit={onOverlayExit}
        onOverlayClose={onOverlayCloseHandler}
        open={open}
        withoutDividers={withoutDividers}
        OverlayTitle={header}
        OverlayActions={
          <CalendarFooter
            buttonProps={buttonProps}
            clearDatesButton={clearDatesButton}
            fullScreenDialog={fullScreenDialog}
            noEndDateButton={noEndDateLabel}
            onClearDates={onDatesClear}
            onNoEndDate={onNoEndDateHandler}
            onOverlayClose={onOverlayCloseHandler}
            onOverlayDone={onOverlayDoneHandler}
            overlayCancelBtnLabel={T('Cancel')}
            overlayDoneBtnLabel={overlayDoneBtnLabel}
            showCancelButton={showCancelButton}
            showNoEndDateButton={focused === FOCUSED_FIELD.TO && noEndDate}
            showOverlayDoneButton={!autoClose}
          />
        }
        OverlayInfo={
          <CalendarInfo
            additionalInfo={AdditionalInfo}
            minimumPeriod={minimumPeriod}
            maximumPeriod={maximumPeriod}
            hasMoveInWindowHint={
              moveInWindows.length > 0 && focused !== FOCUSED_FIELD.TO
            }
            T={T}
          />
        }
      >
        <Calendar
          {...datePickerProps}
          captionSubtitle={CaptionSubtitle}
          highlightMoveInWindows={focused === FOCUSED_FIELD.FROM}
          localeString={localeString}
          moveInWindows={moveInWindows}
          moveInWindowsTooltip={moveInWindowsTooltip}
        />
      </Overlay>
    </Grid>
  );
};

export { DateRangePickerOverlay };
