import React from 'react';

import { Form, Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import isNil from 'lodash-es/isNil';
import { Element as ScrollElement } from 'react-scroll';
import { makeStyles } from 'tss-react/mui';

import { availabilityToDateRage, normalizeDate } from '@ha/date';
import { Button, Grid, units } from '@hbf/dsl/legacy';

import { Experiments } from 'ha/constants/experiments';
import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';

import { getFlexDaysAnalyticsLabel } from 'ha/helpers/getFlexDaysAnalyticsLabel';
import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { useFeatureFlags } from 'ha/modules/FeatureFlags';
import { getFilterTypesForAnalytics } from 'ha/utils/filters/getFilterTypesForAnalytics';

import { Experiment, Variant } from 'ha/modules/Experiments';
import { usePage } from 'ha/modules/Page/contexts';

import { submitSearch } from '../../actions';
import { getInitialValues } from '../../selectors/getInitialValues';

import { HomeDateRangePicker as DateRangePicker } from './DateRangePicker';
import { HomeDurationSelector } from './DurationSelector';
import { LocationsInput } from './LocationsInput';
import { HomeMoveInSelector } from './MoveInSelector';
import { FormValues } from './types';
import { validateForm } from './validateForm';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.yx(0.25, 0.5),
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: 'inherit',
    '&:hover': {
      boxShadow: '0 2px 9px 0 rgba(146, 83, 134, 0.35)',
    },
    height: units.rem(3.5),
    fontSize: units.rem(1.125),
    fontWeight: 'bold',
  },
}));

const AvailabilityFilter: React.FC = () => {
  return (
    <Experiment name={Experiments.TNT478.name}>
      <Variant name="A">
        <React.Fragment>
          <Grid item md={2} xs={6}>
            <Field name="moveIn" component={HomeMoveInSelector} />
          </Grid>
          <Grid item md={2} xs={6}>
            <Field name="duration" component={HomeDurationSelector} />
          </Grid>
        </React.Fragment>
      </Variant>
      <Variant name="B">
        <Grid item xs={12} md={4}>
          <Field name="dateRange" component={DateRangePicker} />
        </Grid>
      </Variant>
    </Experiment>
  );
};

export const SearchBar: React.FC<{
  searchInputRef?: React.RefObject<HTMLInputElement>;
}> = ({ searchInputRef }) => {
  const { T, lang, urlResolver } = useIntl();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initialValues = useSelector(getInitialValues);
  const trackEvent = useTrackEvent();
  const { category } = usePage();
  const { flexibleSearchDates } = useFeatureFlags();
  const isFlexibleSearchDatesEnabled = flexibleSearchDates === 'on';

  const onSubmit = React.useCallback(
    (formValues: FormValues) => {
      const getDatesSelected = () => {
        if (!formValues.dateRange.startDate && !formValues.dateRange.endDate) {
          return 'not selected';
        }
        if (!formValues.dateRange.endDate) return 'move-in';
        return 'dates selected';
      };

      const splitPlace = formValues.place.split(', ');

      if (isFlexibleSearchDatesEnabled && formValues.moveIn) {
        formValues.dateRange = availabilityToDateRage({
          moveIn: {
            type: 'date',
            value: formValues.moveIn,
          },
          duration: formValues.duration,
        });
      }

      const normalizedDateRange = {
        startDate: normalizeDate(formValues.dateRange.startDate),
        endDate: normalizeDate(formValues.dateRange.endDate),
      };

      trackEvent('Clicked Search button', {
        ...normalizedDateRange,
        flexibility: getFlexDaysAnalyticsLabel(
          isNil(formValues.dateRange.flexDays)
            ? DEFAULT_SEARCH_FLEXIBILITY.Home
            : formValues.dateRange.flexDays,
        ),
        listingcity: splitPlace[0],
        country: splitPlace[splitPlace.length - 1],
        selectedDates: getDatesSelected(),
        page: category,
        ...getFilterTypesForAnalytics({
          ...normalizedDateRange,
        }),
      });

      dispatch(submitSearch({ formValues, lang, urlResolver }));
    },
    [
      category,
      dispatch,
      isFlexibleSearchDatesEnabled,
      lang,
      trackEvent,
      urlResolver,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      // TODO: when the experiment is finished, we need to add this value directly into initialValues
      initialValues={initialValues}
      validate={values => validateForm(values, T)}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} className={classes.container}>
            <Grid item xs={12} md={6}>
              <ScrollElement name="homepage.searchField">
                <Field
                  name="place"
                  id="place"
                  component={LocationsInput}
                  placeholder={T('Where will you go?')}
                  predictionTypes={['(cities)']}
                  dataTestLocator="Location Input Box"
                  searchInputRef={searchInputRef}
                />
              </ScrollElement>
            </Grid>

            {isFlexibleSearchDatesEnabled ? (
              <AvailabilityFilter />
            ) : (
              <Grid item xs={12} md={4}>
                <Field name="dateRange" component={DateRangePicker} />
              </Grid>
            )}

            <Grid item xs={12} md={2}>
              <Button
                disabled={submitting}
                type="submit"
                fullWidth
                data-test-locator="Search and book"
                variant="contained"
                color="primary"
                classes={{
                  root: classes.button,
                }}
              >
                {T('Search')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};
