import React from 'react';
import { useDayPicker, useNavigation, CaptionProps } from 'react-day-picker';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

import { ArrowButtonLeft, ArrowButtonRight } from '@hbf/icons/streamline-bold';
import { Typography, IconButton, units } from '@hbf/dsl/legacy';
import { DayJSLocaleUtils } from '@ha/date';

import { useCalendarContext } from './CalendarContext';

const useStyles = makeStyles()((theme: Theme) => ({
  captionContainer: {
    padding: units.trbl(2, 2, 1, 2),
  },
  captionTitle: {
    color: theme.palette.grey[700],
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: units.rem(1.25),
    marginBottom: units.rem(0.5),
  },
  leftButton: {
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[600],
    position: 'absolute',
    top: units.rem(1.4),
    [theme.breakpoints.up('sm')]: {
      left: units.rem(0.8),
    },
  },
  rightButton: {
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[600],
    position: 'absolute',
    top: units.rem(1.4),
    right: units.rem(0),
    [theme.breakpoints.up('sm')]: {
      right: units.rem(0.8),
    },
    left: 'auto',
  },
}));

const Caption: React.FC<CaptionProps> = props => {
  const { displayMonth } = props;
  const { classes, cx } = useStyles();
  const { locale, classNames } = useDayPicker();
  const { previousMonth, goToMonth, nextMonth } = useNavigation();
  const { captionSubtitle } = useCalendarContext();

  return (
    <React.Fragment>
      <div
        className={classes.captionContainer}
        data-test-locator="DatePicker/Calendar/Caption"
      >
        <Typography.H3
          className={cx(classes.captionTitle, classNames?.caption)}
        >
          {DayJSLocaleUtils.formatMonthTitle(
            displayMonth,
            locale.code?.toLowerCase(),
          )}
        </Typography.H3>
        {captionSubtitle && <div>{captionSubtitle(displayMonth)}</div>}
      </div>
      <div data-test-locator="DatePicker/Calendar/NavBar">
        {previousMonth && (
          <IconButton
            className={classes.leftButton}
            onClick={() => goToMonth(previousMonth)}
            data-test-locator="DatePicker/Calendar/NavBar/PrevArrow"
            size="large"
          >
            <ArrowButtonLeft fontSize="small" />
          </IconButton>
        )}
        {nextMonth && (
          <IconButton
            className={classes.rightButton}
            onClick={() => goToMonth(nextMonth)}
            data-test-locator="DatePicker/Calendar/NavBar/NextArrow"
            size="large"
          >
            <ArrowButtonRight fontSize="small" />
          </IconButton>
        )}
      </div>
    </React.Fragment>
  );
};

export { Caption };
