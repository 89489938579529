import uniqBy from 'lodash-es/uniqBy';
import { createSelector } from 'reselect';

import { getSavedSearches } from './reducer';

export const getInitialItems = createSelector(
  [getSavedSearches],
  savedSearches => {
    const formatedList = savedSearches.map(savedSearch => ({
      id: savedSearch.cityLocalized,
      label: [savedSearch.cityLocalized, savedSearch.countryLocalized].join(
        ', ',
      ),
    }));

    return uniqBy(formatedList, 'id');
  },
);
