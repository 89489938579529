import React from 'react';
import debounce from 'debounce';

import { RenderInputFn } from './Autocomplete';
import {
  SimpleItem,
  LocationAutocompleteInput,
} from './LocationAutocompleteInput';

interface RequiredProps {
  // loadSuggestion service
  loadSuggestions: (
    value: string,
  ) => Promise<google.maps.places.AutocompletePrediction[]>;
  // event called when the input changed
  onChange: (value: string) => void;
  // event called the the input is focused
  onFocus?: () => void;
  // event called when an item from the list is selected
  onSelect: (value: string, item: SimpleItem) => void;
  // type of prediction made by google service
  predictionTypes: string[];
  // the input we want to render in order to search for cities
  renderInput: RenderInputFn;
  // Control when render inital items and when fethced ones
  renderInitialItems?: boolean;
  // list of initial items
  initialItems?: SimpleItem[];
  clearIcon?: React.ReactNode;
}

interface DefaultProps {
  value?: string;
  id?: string;
}

type Props = RequiredProps & DefaultProps;

export const MIN_SEARCH_INPUT_LENGTH = 3;

export const Locations: React.FC<Props> = ({
  loadSuggestions,
  onChange,
  onSelect,
  onFocus,
  renderInput,
  value = '',
  renderInitialItems,
  initialItems,
  id,
  clearIcon,
}) => {
  const [items, setItems] = React.useState<SimpleItem[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);

  const getItemsFromPredictions = React.useCallback(
    (places: google.maps.places.AutocompletePrediction[]): SimpleItem[] =>
      places.map(({ description, place_id }, index) => ({
        id: index.toString(),
        label: description,
        placeId: place_id,
      })),
    [],
  );

  const loadItems = React.useCallback(
    (newValue: string) => {
      if (
        newValue &&
        newValue.length > MIN_SEARCH_INPUT_LENGTH &&
        selectedItem !== newValue
      ) {
        loadSuggestions(newValue)
          .then(result => {
            setItems(getItemsFromPredictions(result));
          })
          .catch(() => setItems([]));
      }
    },
    [selectedItem, loadSuggestions, getItemsFromPredictions],
  );

  const loadItemsDebounced = React.useCallback(debounce(loadItems, 400), [
    loadItems,
  ]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      onChange(newValue);
      if (!newValue || !newValue.length) {
        // if user cleared the input then we should clean up the suggestions
        setItems([]);

        return;
      }
      loadItemsDebounced(newValue);
    },
    [onChange, loadItemsDebounced],
  );

  const handleSelect = React.useCallback(
    (newValue: string, item: SimpleItem) => {
      setItems([]);

      setSelectedItem(newValue);
      if (newValue !== undefined) {
        onSelect(newValue, item);
      }
    },
    [onSelect],
  );

  return (
    <LocationAutocompleteInput
      id={id}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      onSelect={handleSelect}
      items={renderInitialItems && initialItems ? initialItems : items}
      renderInput={renderInput}
      clearIcon={clearIcon}
    />
  );
};
