import { DateRange } from 'react-day-picker';
import { isWithinInterval } from 'date-fns';

const isDayInMoveInWindow = (moveInWindows: DateRange[], day: Date) =>
  moveInWindows.some(
    ({ from, to }) =>
      from &&
      to &&
      isWithinInterval(day, {
        start: new Date(from.getFullYear(), from.getMonth(), from.getDate()),
        end: new Date(to.getFullYear(), to.getMonth(), to.getDate()),
      }),
  );

export { isDayInMoveInWindow };
