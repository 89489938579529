import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { TextField, TextFieldProps, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  textField: {
    borderRadius: '4px',
  },
  input: {
    color: theme.palette.grey[700],
    textIndent: units.rem(1),
    fontSize: units.rem(1.25),
    lineHeight: 'normal',
    boxSizing: 'border-box',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    height: units.rem(3.5),
    borderRadius: units.rem(0.25),
    '&:before': {
      borderBottomColor: 'transparent',
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
}));

export const SearchBarTextField: React.FC<TextFieldProps> = ({
  className,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  return (
    <TextField
      {...rest}
      className={cx(classes.textField, className)}
      InputProps={{
        ...rest?.InputProps,
        classes: {
          ...rest?.InputProps?.classes,
          root: cx(rest?.InputProps?.classes?.root, classes.root),
          input: cx(rest?.InputProps?.classes?.input, classes.input),
        },
      }}
    />
  );
};
