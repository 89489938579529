import { defineKey as T } from '@ha/intl';

export const HOME_IMGIX_PATH = 'pages/home';

// CSS transitions speed milliseconds
export const TRANSITION_SPEED = 200;

export const ErrorMessages = {
  SEARCH_NOT_FOUND: T('We could not find the city searched'),
  SERVER_ERROR: T(
    'There was a problem processing your request. Please contact our support if the error persists.',
  ),
};

export const Titles = {
  en: 'HousingAnywhere: Mid to Long-Term Rentals in 400+ cities',
  fr: 'HousingAnywhere: Location moyenne et longue durée dans 400 villes',
  de: 'HousingAnywhere: Mittel- & Langfristige Unterkünfte | 400+ Städte',
  es: 'HousingAnywhere: Alquiler a medio y largo plazo en 400 ciudades',
  it: 'HousingAnywhere: Affitti a medio e lungo termine | 400 città',
  nl: 'HousingAnywhere: Mid- tot langetermijn huurwoningen | 400+ steden',
  pt: 'HousingAnywhere: Aluguel de médio e longo prazo em 400 cidades',
};
