import { isWithinInterval } from 'date-fns';
import { DateRange } from '../types';

const isDayExcluded = (day: Date, exclusions: DateRange[]) =>
  exclusions.some(
    exclusion =>
      exclusion.from &&
      exclusion.to &&
      isWithinInterval(day, {
        start: new Date(
          exclusion.from.getFullYear(),
          exclusion.from.getMonth(),
          exclusion.from.getDate(),
        ),
        end: new Date(
          exclusion.to.getFullYear(),
          exclusion.to.getMonth(),
          exclusion.to.getDate(),
        ),
      }),
  );

export { isDayExcluded };
