import { addDays } from '@ha/date';
import { getMinimumSelectedDays } from './getMinimumSelectedDays';

// Calculates the first available "To" date: "From" date + minimal rental period (in days)
const calcFirstAvailableToDate = (
  from: Date,
  minimumPeriod: number,
  listingFlexibleDays: number,
): Date => {
  const minimumSelectedDays = getMinimumSelectedDays(
    from.toISOString(),
    minimumPeriod,
    listingFlexibleDays,
  );

  return new Date(addDays(from.toISOString(), minimumSelectedDays));
};

export { calcFirstAvailableToDate };
