import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { getSavedSearches } from 'ha/modules/SavedSearches/reducer';
import { UserSearch } from '@ha/api/v2/types/UserSearch';
import { NO_START, NO_END } from '@ha/date';

import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';

import { DatePickerFieldValue } from '../types';

interface InitialValues {
  readonly place: string;
  readonly dateRange: DatePickerFieldValue;
}

const emptyInitialValues: InitialValues = {
  place: '',
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
};

const isValidDate = (date?: string): boolean =>
  dayjs(date).isValid() && date !== NO_START && date !== NO_END;

const getDateProps = ({
  startDate,
  endDate,
}: UserSearch): DatePickerFieldValue => ({
  startDate: isValidDate(startDate) ? new Date(startDate) : undefined,
  endDate: isValidDate(endDate) ? new Date(endDate) : undefined,
  flexDays: DEFAULT_SEARCH_FLEXIBILITY.Home,
});

const initialValuesForSavedSearch = (
  savedSearch: UserSearch,
): InitialValues => ({
  place: [savedSearch.cityLocalized, savedSearch.countryLocalized].join(', '),
  dateRange: getDateProps(savedSearch),
});

const initialValues = (savedSearches: UserSearch[]): InitialValues =>
  savedSearches.length > 0
    ? initialValuesForSavedSearch(savedSearches[0])
    : emptyInitialValues;

const getInitialValues = createSelector([getSavedSearches], initialValues);

export { getInitialValues };
