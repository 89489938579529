import React from 'react';
import {
  useDayRender,
  isMatch,
  useDayPicker,
  DayProps,
} from 'react-day-picker';
import { makeStyles } from 'tss-react/mui';

import { IconButton, Tooltip, Typography, units } from '@hbf/dsl/legacy';

import { isDayInMoveInWindow } from '../helpers/isDayInMoveInWindow';
import { makeDayTestLocator } from '../helpers/makeDayTestLocator';
import { DAY_COLUMN_SIZE_DESKTOP, DAY_COLUMN_SIZE_MOBILE } from '../constants';
import { useCalendarContext } from './CalendarContext';

const useStyles = makeStyles()(theme => ({
  dayButton: {
    width: DAY_COLUMN_SIZE_DESKTOP,
    height: DAY_COLUMN_SIZE_DESKTOP,
    padding: 0,

    [theme.breakpoints.down('md')]: {
      width: DAY_COLUMN_SIZE_MOBILE,
      height: DAY_COLUMN_SIZE_MOBILE,
    },

    '&[disabled]': {
      color: theme.palette.grey[300],
    },
  },
  moveInWindow: {
    border: units.border(1, 'solid', theme.palette.secondary.dark),
  },
  outsideMoveInWindow: {
    cursor: 'not-allowed',
  },
}));

interface DayWithTooltipProps {
  children: React.ReactElement;
  moveInWindowsTooltip: string;
  showTooltip: boolean;
}

const DayWithTooltip: React.FC<DayWithTooltipProps> = ({
  children,
  moveInWindowsTooltip,
  showTooltip,
}) => {
  if (!showTooltip) {
    return children;
  }

  return (
    <Tooltip
      data-test-locator="Calendar / Day / Move-in tooltip"
      placement="top"
      title={moveInWindowsTooltip}
      leaveTouchDelay={4000}
    >
      {children}
    </Tooltip>
  );
};

const Day: React.FC<DayProps> = props => {
  const { modifiers } = useDayPicker();
  const { date } = props;
  const {
    highlightMoveInWindows = false,
    moveInWindows = [],
    moveInWindowsTooltip,
    customTooltip: CustomTooltip,
  } = useCalendarContext();
  const {
    classes: { dayButton, moveInWindow, outsideMoveInWindow },
    cx,
  } = useStyles();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { isHidden, buttonProps } = useDayRender(
    date,
    props.displayMonth,
    buttonRef,
  );

  const isInMoveInWindow = isDayInMoveInWindow(moveInWindows, date);

  const highlightMoveInWindow = React.useCallback(() => {
    if (!modifiers || !highlightMoveInWindows) {
      return false;
    }

    const { start, end, disabled } = modifiers;

    if (start || end) {
      return false;
    }

    if (isMatch(date, Array.isArray(disabled) ? disabled : [disabled])) {
      return false;
    }

    return isInMoveInWindow;
  }, [date, highlightMoveInWindows, isInMoveInWindow, modifiers]);

  const makeDayButtonTestLocator = React.useCallback(() => {
    const defaultLocator = `Calendar / Day / ${makeDayTestLocator(date)}`;

    if (isInMoveInWindow && highlightMoveInWindows) {
      return `${defaultLocator} / MoveIn`;
    }

    if (!modifiers) {
      return defaultLocator;
    }

    const { disabled, end, start } = modifiers;

    if (isMatch(date, Array.isArray(disabled) ? disabled : [disabled])) {
      return `${defaultLocator} / Disabled`;
    }
    if (isMatch(date, Array.isArray(end) ? end : [end])) {
      return `${defaultLocator} / End`;
    }
    if (isMatch(date, Array.isArray(start) ? start : [start])) {
      return `${defaultLocator} / Start`;
    }

    return defaultLocator;
  }, [date, highlightMoveInWindows, isInMoveInWindow, modifiers]);

  const shouldApplyMoveInWindows =
    highlightMoveInWindows && moveInWindows.length > 0;

  const isDayBlocked = React.useMemo(() => {
    if (!modifiers || !modifiers.blocked) {
      return false;
    }

    const { blocked } = modifiers;

    return isMatch(date, Array.isArray(blocked) ? blocked : [blocked]);
  }, [modifiers, date]);

  const handleDayClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (shouldApplyMoveInWindows && !isInMoveInWindow) {
        e.stopPropagation();
        return;
      }

      if (buttonProps.onClick) {
        buttonProps.onClick(e);
      }
    },
    [shouldApplyMoveInWindows, isInMoveInWindow, buttonProps],
  );

  let content = (
    <IconButton
      ref={buttonRef}
      onMouseEnter={buttonProps.onMouseEnter}
      disabled={buttonProps.disabled}
      className={cx(buttonProps.className, dayButton, {
        [moveInWindow]: highlightMoveInWindow(),
        [outsideMoveInWindow]: shouldApplyMoveInWindows && !isInMoveInWindow,
        dayBlocked: isDayBlocked,
      })}
      color="inherit"
      data-test-locator={makeDayButtonTestLocator()}
      onClick={handleDayClick}
      size="large"
    >
      <Typography.Paragraph
        color="inherit"
        data-test-locator={makeDayTestLocator(date)}
      >
        {date.getDate()}
      </Typography.Paragraph>
    </IconButton>
  );

  if (isHidden) {
    content = <div />;
  }

  const isDayBooked = React.useMemo(() => {
    if (!modifiers || !modifiers.booked) {
      return false;
    }

    const { booked } = modifiers;

    return isMatch(date, Array.isArray(booked) ? booked : [booked]);
  }, [date, modifiers]);

  return CustomTooltip && isDayBooked ? (
    <CustomTooltip day={date}>{content}</CustomTooltip>
  ) : (
    <DayWithTooltip
      moveInWindowsTooltip={moveInWindowsTooltip}
      showTooltip={shouldApplyMoveInWindows && !isInMoveInWindow}
    >
      {content}
    </DayWithTooltip>
  );
};

export { Day };
