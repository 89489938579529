import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { Grid, units, TextFieldProps } from '@hbf/dsl/legacy';

import { SearchBarTextField } from './SearchBarTextField';

const useStyles = makeStyles()(() => ({
  margin: {
    marginRight: units.rem(0.5),
  },
  grow: {
    flexGrow: 1,
  },
}));

interface DatePickerInputsProps {
  from: string;
  fromInputProps: TextFieldProps;
  toInputProps: TextFieldProps;
  to: string;
  isFromFocused: boolean;
  isToFocused: boolean;
  onFromFocus: () => void;
  onToFocus: () => void;
  onClearDates?: () => void;
}
const DateRangePickerInputs: React.FC<DatePickerInputsProps> = ({
  from,
  isFromFocused,
  onFromFocus,
  to,
  isToFocused,
  onToFocus,
}) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  // to owerwrite behavior in @ha/marketplace/feature-date-picker DateRangePickerInputs
  // that sets label as a value
  const fromDefaultValue = T('Move-in date');
  const toDefaultValue = T('Move-out date');

  return (
    <Grid container wrap="nowrap">
      <SearchBarTextField
        className={cx(classes.margin, classes.grow)}
        inputProps={{ readOnly: true }}
        placeholder={T('Move in')}
        onClick={onFromFocus}
        focused={isFromFocused}
        value={from === fromDefaultValue ? '' : from}
        data-test-locator="Start Date Home Next"
      />
      <SearchBarTextField
        className={classes.grow}
        inputProps={{ readOnly: true }}
        placeholder={T('Move out')}
        onClick={onToFocus}
        focused={isToFocused}
        value={to === toDefaultValue ? '' : to}
        data-test-locator="End Date Home Next"
      />
    </Grid>
  );
};

export { DateRangePickerInputs };
