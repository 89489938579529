import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  units,
  IconButton,
  Grid,
  Typography,
} from '@hbf/dsl/legacy';

import { OverlayProps } from '../types';
import { DialogTitle as DatePickerDialogTitle } from './Dialog/DialogTitle';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: units.rem(1),
  },
  closeButton: {
    position: 'relative',
    right: units.rem(-1),
  },
  dateLabel: {
    padding: units.yx(0, 1.5),
  },
  dialogContentRoot: {
    padding: units.yx(0, 0.5),
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  dialogRoot: {
    // Iphone 5/SE
    [theme.breakpoints.down(321)]: {
      borderRadius: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      maxWidth: '100%',
      maxHeight: 'none',
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: units.rem(1.125),
    },
  },
  dialogTitleRoot: {
    padding: units.yx(0.75, 1),
  },
  flexDays: {
    paddingLeft: units.rem(0.75),
    width: '100%',
  },
  footer: {
    padding: units.rem(1),

    [theme.breakpoints.up('md')]: {
      padding: units.rem(0.5),
      paddingBottom: units.rem(1.5),
    },
  },
  titleWrapper: {
    paddingTop: units.rem(0.25),
    paddingBottom: units.rem(0.25),
    marginBottom: units.rem(1),
    borderBottom: units.border(1, 'solid', theme.palette.grey[300]),

    [theme.breakpoints.up('md')]: {
      padding: units.trbl(1, 1.5, 0.8, 1.5),
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
}));

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

const DatePickerDialog: React.FC<OverlayProps> = ({
  children,
  focused,
  from,
  MoveInMoveOutTitle,
  onFieldFocusChange,
  onOverlayClose,
  onOverlayExit,
  open,
  OverlayTitle,
  OverlayActions,
  OverlayInfo,
  to,
  formatDate,
  T,
  withoutDividers = false,
  dialogTitle,
  fullScreenDialog = false,
  FlexibleDatesOption,
}) => {
  const { classes } = useStyles();

  return (
    <Dialog
      data-test-locator="DatePicker / Overlay / Dialog"
      maxWidth="md"
      fullScreen={fullScreenDialog}
      onClose={onOverlayClose}
      open={open}
      PaperProps={{
        classes: { root: classes.dialogRoot },
      }}
      TransitionComponent={Transition}
      TransitionProps={{
        onExit: onOverlayExit,
      }}
    >
      {dialogTitle ? (
        <DialogTitle className={classes.titleWrapper}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography.H3
                fontWeight="normal"
                className={classes.dialogTitle}
              >
                {dialogTitle}
              </Typography.H3>
            </Grid>

            <IconButton
              className={classes.closeButton}
              aria-label={T('close')}
              onClick={onOverlayClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
      ) : (
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          <DatePickerDialogTitle
            from={from}
            focused={focused}
            onFieldFocusChange={onFieldFocusChange}
            to={to}
            formatDate={formatDate}
            T={T}
          />
        </DialogTitle>
      )}

      <DialogContent
        classes={{ root: classes.dialogContentRoot }}
        dividers={!withoutDividers}
      >
        {dialogTitle && (
          <Typography.Paragraph
            className={classes.dateLabel}
            align={fullScreenDialog ? 'center' : 'left'}
          >
            {`${formatDate('D MMM YYYY', from)} - ${formatDate(
              'D MMM YYYY',
              to,
            )}`}
          </Typography.Paragraph>
        )}
        {OverlayTitle}
        <Grid container justifyContent="center" className={classes.container}>
          {MoveInMoveOutTitle}
          <div className={classes.flexDays}>{FlexibleDatesOption}</div>
        </Grid>
        {children}
        {OverlayInfo}
      </DialogContent>
      <DialogActions className={dialogTitle && classes.footer}>
        {OverlayActions}
      </DialogActions>
    </Dialog>
  );
};

export { DatePickerDialog };
