import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  popoverActionsContainer: {
    paddingBottom: units.rem(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: units.rem(0.5),
    },
  },
}));

interface Props {
  children: React.ReactNode;
}

const PopoverActions: React.FC<Props> = props => {
  const { children } = props;
  const {
    classes: { popoverActionsContainer },
  } = useStyles();

  return <div className={popoverActionsContainer}>{children}</div>;
};

export { PopoverActions };
