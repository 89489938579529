import { calculateMinimumSelectedDays } from './calculateMinimumSelectedDays';

const getEarliestBookingStartDate = (startDate: string, minimumDate?: string) =>
  startDate || minimumDate;

const getMinimumSelectedDays = (
  startDate: string,
  minimalPeriod: number,
  listingFlexibleDays: number,
  minimumDate?: string,
): number =>
  calculateMinimumSelectedDays(
    getEarliestBookingStartDate(startDate, minimumDate) || '',
    minimalPeriod,
    listingFlexibleDays,
  );
export { getMinimumSelectedDays };
