import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Collapse,
  Grid,
  Paper,
  Radio,
  Typography,
  units,
  AlertLabs,
} from '@hbf/dsl/legacy';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles()(theme => ({
  active: {
    border: units.border(1, 'solid', theme.palette.secondary.main),
    background: theme.palette.common.white,
  },
  inactive: {
    border: units.border(1, 'solid', theme.palette.grey[300]),
    background: theme.palette.grey[50],
  },
  error: {
    border: units.border(1, 'solid', theme.palette.error.main),
  },
  container: {
    padding: theme.spacing(1.5, 2),
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
  radioButton: {
    padding: units.trbl(0.1, 0.8, 0.1, 0.2),
  },
  errorWrapper: {
    background: theme.palette.error.light,
    margin: theme.spacing(-1.5, -2, 1.5),
    borderRadius: units.trbl(0.25, 0.25, 0, 0),

    '.MuiSvgIcon-root': {
      fill: theme.palette.white.main,
    },
  },
  errorText: {
    color: theme.palette.white.main,
    marginTop: theme.spacing(-0.25),
  },
}));

export interface Props {
  className?: string;
  handleChange: (checked: boolean) => void;
  isActive?: boolean;
  onActiveExpandContent?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  subtitleContent?: React.ReactNode | null;
  titleContent: React.ReactNode | null;
  value: string;
  showRadio?: boolean;
  errorMessage?: string;
  ContentComponent?: React.FC<React.PropsWithChildren>;
  SubtitleContentComponent?: React.FC<React.PropsWithChildren>;
  testLocator?: string;
  disabled?: boolean;
}

const RadioButtonCard: React.FC<Props> = ({
  className = '',
  handleChange,
  isActive = false,
  onActiveExpandContent,
  onClick,
  subtitleContent = null,
  titleContent,
  value,
  showRadio = true,
  errorMessage,
  ContentComponent = Typography.ParagraphTitle,
  SubtitleContentComponent = Typography.Paragraph,
  testLocator,
  disabled,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Paper
      elevation={0}
      className={className}
      role="button"
      onClick={onClick}
      classes={{
        root: cx(
          classes.container,
          classes[isActive ? 'active' : 'inactive'],
          classes[errorMessage ? 'error' : ''],
        ),
      }}
      data-test-locator={`Choose ${value}`}
    >
      <Collapse in={Boolean(errorMessage) && isActive}>
        <AlertLabs
          icon={<InfoIcon />}
          severity="error"
          classes={{ root: classes.errorWrapper }}
          data-test-locator="PayInForm/ErrorMessage"
        >
          <Typography.Paragraph
            className={classes.errorText}
            data-test-locator="PayInForm/ErrorMessageText"
          >
            {errorMessage}
          </Typography.Paragraph>
        </AlertLabs>
      </Collapse>

      <Grid alignItems="flex-start" container data-test-locator={testLocator}>
        <Grid item xs="auto">
          {showRadio && (
            <Radio
              color="secondary"
              checked={isActive}
              className={classes.radioButton}
              onChange={(_, checked: boolean) => handleChange(checked)}
              value={value}
              data-test-locator="Radio Button Card"
              disabled={disabled}
            />
          )}
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            <Grid item>
              <ContentComponent>{titleContent}</ContentComponent>
            </Grid>
            {subtitleContent && (
              <Grid item>
                <SubtitleContentComponent>
                  {subtitleContent}
                </SubtitleContentComponent>
              </Grid>
            )}
            <Collapse in={!!onActiveExpandContent && isActive}>
              <Grid item>{onActiveExpandContent}</Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { RadioButtonCard };
